export const EnChatStrings = {
  HEADER_ANNOUNCEMENT: "Announcement",
  HEADER_GROUP: "Group",
  HEADER_INDIVIDUAL: "Contacts",
  HEADER_FAVORITE: "Favorite",
  HEADER_ARCHIVED: "Archived",
  HEADER_MESSAGE: "Message",

  ADD_FAVORITE: "Add to favorite",
  REMOTE_FAVORITE: "Remove from favorite",
  SEE_MORE: "See more",

  CONFIRM_FORWARD: "Confirm forward message to {{USER_NAME}}?",

  RECEIVED_FILE: "Received a file",
  SENT_FILE: "Sent a file",
  RECEIVED_STICKER: "{{SENDER}} sent a sticker",
  SENT_STICKER: "You sent a sticker",
  REMOVE: "Remove",
  ADD_ARCHIVE: "Archive",
  REMOTE_ARCHIVE: "Unarchive",

  NEW_GROUP: "New Group",
  BROADCAST: "Broadcast",
  SEARCH_MESSAGE: "Search Message",
  SORT_TIME: "Sort by time",
  SORT_GROUP: "Sort by group",
  SEARCH_UNFILTERED: "Filter",
  SEARCH_FILTERED_ONE: "Filter with 1 user",
  SEARCH_FILTERED_MANY: "Filter with {{COUNT}} users",

  RESTRICTED_CHAT_OPEN_IN_APP_MSG: "For security reason, this chatroom can only open in App.",
  CONFIDENTIAL_MSG_PREVIEW: "**Confidential message**",

  ENCRYPTED_MSG_PREVIEW: "**Secure message**",

  CHAT_GROUP: {
    GROUP_NAME: "Group Name",
    ADD_MEMBER: "Add Member",
    ADD_MEMBER_FROM_CHAT_GROUP: "Add Members From Chat Group",
    ADD_MEMBER_FROM_BROADCAST: "Add Members From Broadcast Group",
    NO_MEMBER: "No member in group",
    MEMBER_COUNT: {
      ONE: "{{NUM}} member in group",
      MULTIPLE: "{{NUM}} members in group"
    },
    NORMAL_GROUP: "Normal Group",
    ANNOUNCEMENT_GROUP: "Announcement Group",
    GROUP_TYPE: "Group type",
    GORUP_LEVEL: "Classification level",

    ERROR_MSG: {
      NO_GROUP_NAME: "Please input group name",
      NO_MEMBER: "Please select group member"
    },

    ASSIGN_ADMIN: "Assign Group Admin",
    ADD_ADMIN: "Add Group Admin",
    REMOVE_MEMBER: "Remove Member",
    REMOVE_MEMBER_CONFIRM: "Are you sure you want to remove {{USER}} from group?",
    NOTIFICATION: {
      TITLE: "Mute Notification",
      ONE_HOUR: "1 hour",
      TWO_HOURS: "2 hours",
      EIGHT_HOURS: "8 hours",
      ONE_WEEK: "1 week",
      ONE_YEAR: "1 year",
      FOREVER: "Forever",
      UNTIL: "Until "
    },
    LEAVE_GROUP: "Leave Group",
    LEAVE_GROUP_CONFIRM: "Are you sure you want to leave this group?",
    DELETE_GROUP: "Delete Group",
    DELETE_GROUP_CONFIRM: "Confirm delete group?",
    REMOVE_ALL: "Remove All Selected Members"
  },

  CONTACT_CARD: {
    WHISPER: "Whisper",
    CHAT: "Chat",
    IMPORTANT_USER: {
      SET: "Important",
      UNSET: "Unimportant"
    }
  }

}
