<div class="out-of-office-modal">
  <div class="toggling" [ngClass]="{'title-bottom-line': isEnableOutOfOfficeScheduler}">
    <div class="text">
      <div class="title">
        {{ 'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE' | translate }}
      </div>
      <div class="time" *ngIf="!isEnableOutOfOfficeScheduler && outOfOfficeExpiredTimestamp != 0">
        {{outOfOfficeExpiredTimestamp | tnDate: dateTimeLocaleDisplay}}
      </div>
    </div>
    <div class="toggle clickable" (click)="toggle()" *ngIf="!isEnableOutOfOfficeScheduler">
      <i class="fa fa-fw fa-2x fa-toggle-on text-success" *ngIf="isOutOfOffice"></i>
      <i class="fa fa-fw fa-2x fa-toggle-off" *ngIf="!isOutOfOffice"></i>
    </div>
  </div>

  <ng-container *ngIf="isEnableOutOfOfficeScheduler; else enableOofScheduler">
    <div class="date-selection">
      <div class="selection-body">
        <div class="calendar-wrapper">
          <!-- <mat-calendar [minDate]="minDate" [(selected)]="offlineDate" (selectedChange)="onDateChange($event)">
          </mat-calendar> -->
          <!-- <mat-calendar (selectedChange)="onDateChange($event)"> </mat-calendar> -->
          <bs-daterangepicker-inline
            bsDaterangepicker
            #drp="bsDaterangepicker"
            [bsConfig]="dateInputConfig"
            [dateCustomClasses]="dateCustomClasses"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [bsValue]="defaultDateRange"
            (bsValueChange)="onDateRangeChange($event)"
          ></bs-daterangepicker-inline>
        </div>
        <div class="selection-detail">
          <div class="selection-mode">
            <!-- <mat-form-field appearance="none">
              <mat-label>Group</mat-label>
              <mat-select [(ngModel)]="group">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of groupOptions" [value]="option.value">
                  {{ option.labelTranslateKey | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <mat-checkbox [(ngModel)]="isAllDay" (ngModelChange)="toggleIsAllDay()">{{
              'DATE.ALL_DAY' | translate
            }}</mat-checkbox>
          </div>
          <div class="start-date-time">
            <span>{{ 'GENERAL.START' | translate }}</span>
            <div class="date-picker-wrapper">
              <span class="date-text" *ngIf="outOfOfficeDateRange[0]">{{
                outOfOfficeDateRange[0] | tnDate : dateTimeLocaleDisplay
              }}</span>
            </div>
            <div class="time-picker-wrapper">
              <timepicker
                [hoursPlaceholder]="'hh'"
                [minutesPlaceholder]="'mm'"
                [showMeridian]="true"
                [meridians]="meridians"
                [showSpinners]="false"
                [(ngModel)]="startDate"
                (isValid)="checkIfStartTimeValid($event)"
                (ngModelChange)="checkTimeInput()"
              ></timepicker>
            </div>
          </div>
          <div class="end-date-time">
            <span>{{ 'GENERAL.END' | translate }}</span>
            <div class="date-picker-wrapper">
              <span class="date-text" *ngIf="outOfOfficeDateRange[1]">{{
                outOfOfficeDateRange[1] | tnDate : dateTimeLocaleDisplay
              }}</span>
            </div>
            <div class="time-picker-wrapper">
              <timepicker
                [hoursPlaceholder]="'hh'"
                [minutesPlaceholder]="'mm'"
                [showMeridian]="true"
                [meridians]="meridians"
                [showSpinners]="false"
                [(ngModel)]="endDate"
                (isValid)="checkIfEndTimeValid($event)"
                (ngModelChange)="checkTimeInput()"
              ></timepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="del-btn-wrapper">
          <h5 class="text-color-primary clickable" *ngIf="isOutOfOffice" (click)="delete()">
            {{ 'GENERAL.DELETE' | translate }}
          </h5>
        </div>
        <button class="btn btn-default" (click)="cancel()">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button class="btn tn-button-primary" [disabled]="!isDateTimeValid" (click)="confirmScheduler()">
          {{ 'GENERAL.SAVE' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #enableOofScheduler>
    <div class="selector" *ngIf="isSelectingDate">
      <div class="msg">
        {{ 'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.NOW_TO' | translate }}
      </div>
      <div class="picker-wrapper">
        <div class="picker-item date">
          <input
            type="text"
            class="form-control"
            placement="bottom"
            #dp="bsDatepicker"
            bsDatepicker
            [bsConfig]="dateInputConfig"
            [minDate]="minDate"
            [(ngModel)]="dateObj"
            (bsValueChange)="onDateChange($event)"
          />
        </div>
        <div class="picker-item time">
          <timepicker [(ngModel)]="dateObj" (ngModelChange)="onTimeChange()"></timepicker>
        </div>
      </div>
      <div class="footer">
        <button class="btn btn-default" (click)="cancel()">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button class="btn tn-button-primary" [disabled]="!isDateValid" (click)="confirm()">
          {{ 'GENERAL.CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
