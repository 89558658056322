<div class="attachment-image-grid-wrapper">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'WEBCLIENT.CHATROOM.PHOTO' | translate}}</h4>
  </div>
  <div class="attachment-grid-body">
    <div *ngFor="let monthAttachments of timelinedAttachments">
      <tn-attachment-timelined [year]="monthAttachments.year" [month]="monthAttachments.month"></tn-attachment-timelined>
      <div class="attachment-grid">
        <div class="image-preview clickable"
          (click)="onClick(image)"
          [ngStyle]="{'background-image': image.imgSrc}"
          *ngFor="let image of monthAttachments.attachments">
          <div class="gif-preview-icon" *ngIf="image.isGif">GIF</div>
        </div>
      </div>
    </div>

    <tn-empty-page 
      [msgKey]="'GENERAL.NO_PHOTO'" 
      *ngIf="attachments.length == 0">
    </tn-empty-page>
  </div>
</div>