import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  promptInput: string = "";

  @ViewChild("promptInputArea", {static: false}) promptInputArea: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('50vw');
  }

  ngAfterViewInit() {
    if (this.promptInputArea) {
      this.promptInputArea.nativeElement.focus();
    }
  }

  cancel() {
    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
    this.dialogRef.close();
  }

  submit() {
    this.data.submitCallback(this.promptInput);
    this.dialogRef.close();
  }

}
