import {Injectable} from '@angular/core';

import notie from 'notie';
import {TranslateManagerService} from '../translate/translate-manager.service';
import {TnDialogService} from '../tn-dialog/tn-dialog.service';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {TeamnoteConfigService} from '../../configs/teamnote-config.service';
import {AlertComponent} from './alert/alert.component';
import {PromptComponent} from './prompt/prompt.component';
import {TnLoaderService} from '../tn-loader/tn-loader.service';
import {HttpClient} from '@angular/common/http';

declare var Notification: any;

interface NotificationConfig {
  body: string;
  icon?: string;
}

@Injectable()
export class TnNotificationService {
  alertRef: any = null;

  notificationDisplayDuration: number = 3;

  constructor(
    private _http: HttpClient,
    private _translateManagerService: TranslateManagerService,
    private _tnDialogService: TnDialogService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _tnLoaderService: TnLoaderService
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.notificationDisplayDuration = config.GENERAL.NOTIFICATION_DISPLAY_DURATION;
    });
  }

  showCustomSuccess(msg: string) {
    notie.alert({
      type: 'success',
      text: msg,
      time: this.notificationDisplayDuration
    });
  }

  showCustomSuccessByTranslateKey(key: string, params?: any) {
    this._translateManagerService.getTranslationByKey(
      key,
      s => {
        this.showCustomSuccess(s)
      },
      params
    )
  }

  showCustomInfo(msg: string) {
    notie.alert({
      type: 'info',
      text: msg,
      time: this.notificationDisplayDuration
    });
  }

  showCustomInfoByTranslateKey(key: string) {
    this._translateManagerService.getTranslationByKey(key, (s) => {
      this.showCustomInfo(s);
    });
  }

  showCustomError(msg: string) {
    notie.alert({
      type: 'error',
      text: msg,
      time: this.notificationDisplayDuration
    });
  }

  showSystemError() {
    const errorMsg = 'GENERAL.SYSTEM_ERROR';
    this.showCustomErrorByTranslateKey(errorMsg);
  }

  showCustomErrorByTranslateKey(key?: string) {
    if (key == null) {
      this.showSystemError();
    } else {
      this._translateManagerService.getTranslationByKey(key, (s) => {
        this.showCustomError(s);
      });
    }
  }

  showCustomWarningByTranslateKey(key: string, param?: any) {
    this._translateManagerService.getTranslationByKey(key, (s) => {
      this.showCustomWarning(s);
    }, param);
  }

  showCustomWarning(msg: string) {
    notie.alert({
      type: 'warning',
      text: msg,
      time: this.notificationDisplayDuration
    });
  }

  showConfirm(msg: string, confirmCallback: Function, cancelCallback?: Function) {
    this._tnDialogService.openTnDialog(ConfirmationComponent, {
      msg: msg,
      confirmCallback: confirmCallback,
      cancelCallback: cancelCallback
    });
  }

  showConfirmByTranslateKey(key: string, confirmCallback: Function, cancelCallback?: Function, param?: any) {
    let text;
    this._translateManagerService.getTranslationByKey(
      key,
      (s) => {
        text = s;
        this.showConfirm(s, confirmCallback, cancelCallback);
      },
      param
    );
  }

  tryToRequestNotificationPermission(): void {
    // console.log(' Notification.permission', Notification.permission);
    if (typeof Notification !== 'undefined') {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }

  async showBrowserNotification(body: string, customCallback?: Function, avatar?: string, isGroup?: boolean) {
    // console.log('notification body', body);
    const closeNoti = (noti: Notification): any => {
      if (noti.close) {
        noti.close();
      }
    };

    let title;
    this._translateManagerService.getTranslationByKey('GENERAL.TEAMNOTE', (s) => {
      title = s;
    });

    // console.log('is tnElectronApi exist?', window['tnElectronApi']);

    if (typeof Notification !== 'undefined') {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      } else if (window['tnElectronApi']) {
        console.log('--- in Electron app ---')
        // Get avatar
        let icon = null;
        let response = null;
        let avatarUrl = `${window.location.pathname}assets/images/general/default_avatar.png`;
        let config: NotificationConfig = {
          body: body
        }

        if (avatar) {
          avatarUrl = `${this._teamnoteConfigService.config.HOST.API_HOST}/static/${avatar}`
        } else {
          if (isGroup) {
            avatarUrl = `${window.location.pathname}assets/images/general/default_group.png`
          }
        }

        response = await this._http.get(avatarUrl, {responseType: 'arraybuffer'}).toPromise();
        // @ts-ignore
        icon = btoa(new Uint8Array(response).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));

        if (icon) {
          config.icon = icon
        }

        // @ts-ignore
        window.tnElectronApi.showNotification(config, () => {
          if (customCallback) {
            customCallback()
          }
        })
        // window.tnElectronApi.showNotification({
        //   body: body,
        //   icon: icon,
        // }, () => {
        //   if (customCallback) {
        //     customCallback();
        //   }
        // });
      } else {
        console.log('###not in Electron app###')
        // let temp_icon = null
        // let response = null;
        // if (avatar) {
        //   response = await this._http.get(`${this._teamnoteConfigService.config.HOST.API_HOST}/static/${avatar}`, {responseType: 'arraybuffer'}).toPromise();
         
        // } else {
        //   if (isGroup) {
        //     response = await this._http.get(`/assets/images/general/default_group.png`, {responseType: 'arraybuffer'}).toPromise();
        //   } else {
        //     response = await this._http.get(`/assets/images/general/default_avatar.png`, {responseType: 'arraybuffer'}).toPromise();
        //   }
        // }

        // // @ts-ignore
        // temp_icon = btoa(new Uint8Array(response).reduce(function (data, byte) {
        //   return data + String.fromCharCode(byte);
        // }, ''));
        // temp_icon = 'data:image/jpeg;base64,' + temp_icon;

        // console.log('avatar', avatar)
        
        const noti = new Notification(
          title,
          {
            icon: avatar ? `${this._teamnoteConfigService.config.HOST.API_HOST}/static/${avatar}` : this._teamnoteConfigService.config.IMAGES.NOTIFICATION_ICON,
            // icon: avatar ? temp_icon : this._teamnoteConfigService.config.IMAGES.NOTIFICATION_ICON,
            // icon: temp_icon,
            body: body
          }
        );
        noti.onclick = (n, event) => {
          if (customCallback) {
            customCallback();
          }
          closeNoti(n);
        };

        setTimeout(closeNoti(noti), 10000);
      }
    }
  }

  showBrowserNotificationByTranslateKey(key: string, customCallback?: Function, param?: any) {
    this._translateManagerService.getTranslationByKey(
      key,
      (s) => {
        this.showBrowserNotification(s, customCallback);
      },
      param
    );


  }

  showAlert(msg?: string, msgTranslateKey?: string, buttonMsg?: string, buttonMsgTranslateKey?: string, buttonAction?: Function): void {
    this.alertRef = this._tnDialogService.openTnDialog(AlertComponent, {
      msg: msg,
      msgTranslateKey: msgTranslateKey,
      buttonMsg: buttonMsg,
      buttonMsgTranslateKey: buttonMsgTranslateKey,
      buttonAction: buttonAction
    });
    this.alertRef.afterClosed().subscribe(() => {
      this._tnLoaderService.hideAlertHandling();
      this.alertRef = null;
    });

    this._tnLoaderService.showAlertHandling();
  }

  showPrompt(msg?: string, msgTranslateKey?: string, cancelCallback?: Function, submitCallback?: Function, isPassword?: boolean, isNoNeedInput?: boolean): void {
    const promtRef = this._tnDialogService.openTnDialog(PromptComponent, {
      msg: msg,
      msgTranslateKey: msgTranslateKey,
      cancelCallback: cancelCallback,
      submitCallback: submitCallback,
      isPassword: isPassword,
      isNoNeedInput: isNoNeedInput
    });
  }

}
