<div class="notification-cell-wrapper cronjob-notification">
  <div class="icon-container" style="width: 40px;">
    <!-- <div class="icon-wrapper"> -->
      <div class="critical-message">
        <div class="critical-msg-icon"></div>
      </div>
    <!-- </div> -->
  </div>
  <div class="info-wrapper">
    <div class="title">
      {{ body.jobInfo?.chatName }}
    </div>
    <div class="title">
      {{ body.jobInfo?.alertMsgTranlsteKey | translate }}
    </div>
    <div class="content text-color-primary">
      {{ body.jobInfo?.alertDisplayDateTime }}
    </div>
  </div>
</div>

