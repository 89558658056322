import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Chat } from '../../../models/chat';

import { ContextMenuService, ContextMenuItem } from '../../../utilities/context-menu/context-menu.service';
import { ChatService } from '../../services/data/chat/chat.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { ChatConstant } from '../../../constants/chat.constant';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { WebclientService } from '../../webclient.service';

import * as _ from 'lodash';
import { ChatRoomService } from '../chat-room/chat-room.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { UserConstant } from '../../../constants/user.constant';
import { AccountManagerService } from '../../services/account/account-manager.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';

@Component({
  selector: 'tn-chat-cell',
  templateUrl: './chat-cell.component.html',
  styleUrls: ['./chat-cell.component.scss']
})
export class ChatCellComponent implements OnInit {
  @Input() chat: Chat;
  @Input() activeChat: Chat;
  @Input() isClickable: boolean = true;
  @Input() isShowContextMenu: boolean = false;
  @Input() isShowLastMsg: boolean = false;
  @Input() isEnableImportantUsers: boolean = false;
  @Output() chatOnClick = new EventEmitter<Chat>();

  MSG_TYPE = MessageTypeConstant;
  PRESENCE_TYPE = PresenceTypeConstant;
  SECURITY_LEVEL = ChatConstant.SECURITY_LEVEL;
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  importantLevel: number = UserConstant.IMPORTANT_LEVEL.NORMAL;
  contextMenus: ContextMenuItem[] = [];

  isEnableFlashAnimation: boolean = false;
  isEnableChatRemove: boolean = false;
  isEnableChatArchive: boolean = false;

  showUserField: boolean;
  userField: any;

  constructor(
    private _contextMenuService: ContextMenuService,
    private _chatService: ChatService,
    private _fileFactoryService: FileFactoryService,
    private _webclientService: WebclientService,
    private _chatRoomService: ChatRoomService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService
  ) { 
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.isEnableFlashAnimation = config.WEBCLIENT.CHATROOM.IS_FLASH_ANIMATION;
      this.isEnableChatRemove = config.WEBCLIENT.GENERAL.IS_ENABLE_CHAT_REMOVE;
      this.isEnableChatArchive = config.WEBCLIENT.GENERAL.IS_ENABLE_CHAT_ARCHIVE;
    })
  }

  ngOnInit() {
    this.getUserField();
  }
  ngOnChanges() {
    if (!this.chat) {
      return;
    }
    if (this.chat.isGroup) {
      this._fileFactoryService.getFileByAttachmentId(
        this.chat.pic,
        (imageUrl) => {
          this.chat.picBgUrl = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    } else {
      this._fileFactoryService.getFileByAttachmentId(
        this.chat.chatTarget.pic,
        (imageUrl) => {
          this.chat.picBgUrl = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );

      this.chat.importantLevel = this._chatService.checkImportantLevelForChat(this.chat)
    }

    // Get initial context menus (in order to show dropdown button)
    this.getContextMenu();
  }

  get isMe() {
    if (!this.chat) {
      return false
    }

    return this.chat.chatTarget?.user_id === this._accountManagerService.userId;
  }
  
  chatClick(event: MouseEvent, isNavigate: boolean): void {
    // Prevent parent div event
    event.stopPropagation();
    this._contextMenuService.hideContextMenu();

    if (isNavigate) {
      this.chatOnClick.emit(this.chat);
    } else {
      this.onChatContextMenu(event);
    }
  }

  getContextMenu(): void {
    if (!this.isShowContextMenu) {
      return;
    }
    this.contextMenus = [];
    // favorite
    if (this.chat.isFavourite) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHAT.REMOTE_FAVORITE',
        action: () => this.onFavoriteChat()
      });
    } else {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHAT.ADD_FAVORITE',
        action: () => this.onFavoriteChat()
      });
    }

    // remove
    if (!this.chat.isFavourite && this.chat.t == this.PRESENCE_TYPE.INDIVIDUAL_CHAT && this.isEnableChatRemove) {
      if (this.isEnableChatArchive) {
        if (!this.chat.isArchived) {
          this.contextMenus.push({
            label: 'WEBCLIENT.CHAT.REMOVE',
            action: () => this.onRemoveChat()
          });
        }
      } else {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHAT.REMOVE',
          action: () => this.onRemoveChat()
        });
      }
    }

    // Archived
    if (this.isEnableChatArchive) {
      if (this.chat.isArchived) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHAT.REMOTE_ARCHIVE',
          action: () => this.onArchiveChat()
        });
      } else {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHAT.ADD_ARCHIVE',
          action: () => this.onArchiveChat()
        });
      }
    }

    // Dev mode
    if (this._webclientService.isDevMode) {
      _.each(this._webclientService.chatRoomTestMessageCounts, (count) => {
        this.contextMenus.push({
          labelString: "[DEV] - Send test messages: " + count,
          action: () => this.sendTestingMessageToChatRoom(count)
        })
      });
    }
  }  

  onChatContextMenu(event): void {
    event.preventDefault();
    if (this.isShowContextMenu) {
      this.getContextMenu();
      this._contextMenuService.showContextMenu(event.clientX, event.clientY, this.contextMenus, this.chat.chat_id);
    }
  }

  onRemoveChat(): void {
    this._chatService.updateChatIsVisibleState(this.chat.chat_id, false);
  }

  onFavoriteChat(): void {
    this._chatService.updateChatIsFavouriteState(this.chat.chat_id, !this.chat.isFavourite);
  }

  onArchiveChat(): void {
    this._chatService.updateChatIsArchivedState(this.chat.chat_id, !this.chat.isArchived);
  }

  sendTestingMessageToChatRoom(numOfMessage: number): void {
    this._tnNotificationService.showConfirm(
      ["[DEV] - Send test messages?", this.chat.displayName, numOfMessage].join(" == "),
      () => {
        this._chatRoomService.sendTestingMessageToChatByChatIdAndCount(this.chat.chat_id, numOfMessage);
      }
    );
  }

  getUserField(): void {
    this.showUserField = false;
    if (this._webclientService.display_user_fields_in_chat && Object.keys(this._webclientService.display_user_fields_in_chat).length > 0) {
      this.showUserField = true;
      this.userField = this._webclientService.display_user_fields_in_chat;
    }
  }
}
