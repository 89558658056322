import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../../../models/message';

@Component({
  selector: 'tn-chat-notification',
  templateUrl: './chat-notification.component.html',
  styleUrls: ['./chat-notification.component.scss']
})
export class ChatNotificationComponent implements OnInit {

  @Input() body: any;

  constructor() { }

  ngOnInit() {
  }

}
