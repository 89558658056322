<div class="login-methods" *ngIf="loginMethods && loginMethods.length > 0">
  <div class="login-or-divider" *ngIf="isAllowTeamNoteLogin">
    - {{'GENERAL.OR' | translate}} -
  </div>
  <div class="login-body-row method-wrapper" *ngFor="let method of loginMethods">
    <button class="btn btn-info btn-block" (click)="onLoginMethodClick(method)">
      Login with {{method.auth_name}}
      <div class="staff-only-text" *ngIf="ssoExtraWordings.length && method.auth_name === 'Single Sign-On' && method?.auth_type === 'saml'">
        {{ssoExtraWordings}}
      </div>
    </button>
  </div>
</div>