<div class="tn-webclient" [class.web-app]="isWebapp">
  <div class="tn-header theme-color-primary" [ngClass]="{'no-base-background-visibility': noBaseBackground}" *ngIf="!isWebapp">
    <div class="nav-logo-wrapper">
      <div class="tn-nav-logo nav-logo" (click)="onDevToggleClick()"></div>
      <div class="topnav-label" *ngIf="NAV_LABEL">
        {{NAV_LABEL}}
      </div>
      <div class="topnav-label red" *ngIf="NAV_UAT_LABEL">
        {{NAV_UAT_LABEL}}
      </div>
      <!-- <div class="build-label" *ngIf="BUILD_LABEL">
        {{BUILD_LABEL}}
      </div> -->
    </div>
    <div class="user-info">
      <div class="logged-in-user">
        <div class="avatar-wrapper">
          <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': avatarImageSrc}"></div>
        </div>
        <div class="name" *ngIf="loggedInUser">
          {{loggedInUser.name}}
        </div>
      </div>

      <tn-notification-center
        *ngIf="ENABLE_NOTIFICATION_CENTER">
      </tn-notification-center>

      <div class="btn-group tn-dropdown-group" dropdown placement="bottom right">
        <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
          <i class="fa fa-angle-down fa-fw"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="openMyProfileModal()">
              {{'WEBCLIENT.MENU.MY_PROFILE' | translate}}
            </div>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="openLanguageSelectModal()">
              {{'GENERAL.LANGUAGE' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngIf="IS_ENABLE_TNC">
            <div class="tn-dropdown-item" (click)="openTAndCModal()">
              {{'GENERAL.TERMS_AND_CONDITIONS' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngFor="let setting of settingMenus">
            <div class="tn-dropdown-item setting-menu" (click)="setting.action()">
              <span>{{setting.labelKey | translate}}</span>
              <div class="text-color-primary out-of-office" *ngIf="setting.labelKey === 'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE' && outOfOfficeRange">
                {{outOfOfficeRange.start_at | tnDate: 'OUT_OF_OFFICE_DATE_TIME'}} - 
                {{outOfOfficeRange.expired_at | tnDate: 'OUT_OF_OFFICE_DATE_TIME'}}
              </div>
            </div>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem" *ngIf="!ELECTRON_HIDE_LOGOUT_BTN || !IS_ELECTRON">
            <div class="tn-dropdown-item" (click)="manualLogout()">
              {{'GENERAL.LOGOUT' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngIf="IS_ENABLE_DEBUG">
            <div class="tn-dropdown-item" (click)="tnDebugger()">
              Download Log
            </div>
          </li>
          <ng-container *ngIf="isDevMode">
            <li role="menuitem">
              <div class="tn-dropdown-item" (click)="simulateDisconnect()">
                [DEV] - Disconnect Socket
              </div>
            </li>
            <li role="menuitem">
              <div class="tn-dropdown-item" (click)="simulateReconnect()">
                [DEV] - Reconnect Socket
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="tn-content-body">
    <div class="tn-sidebar" *ngIf="!isWebapp">
      <tn-side-nav></tn-side-nav>
    </div>

    <div class="tn-content-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="tn-footer" *ngIf="!isWebapp">
    <div class="footer-item copyright">
      {{'GENERAL.COPYRIGHT_MSG' | translate}}
    </div>
    <div class="footer-item version-number">
      <span (click)="onDebugClick()">{{VERSION_NO}}</span>
    </div>
  </div>
</div>
