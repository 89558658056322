// OIDS Config
// Created by Shan - 2019-10-23

import { TeamNoteConfig } from "../../configs/models/config";
import { PageUrlConstant } from '../../constants/page-url.constant';

export const OidsConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "oids"
  },
  GENERAL: {
    PAGE_TITLE: "OIDS",
    NO_BASE_BACKGROUND: true
  },
  NOTIFICATION: {
    PLAY_NOTIFICATION_AUDIO: true,
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/oids/notification_icon.png",
    FAVICON: "assets/images/clients/oids/icon.png",
    FAVICON_ALERT: "assets/images/clients/oids/favicon_alert.png"
  },
  WEBCLIENT: {
    GENERAL: {
      WEBCLIENT_DEFAULT_PAGE: PageUrlConstant.WEBCLIENT.CHAT,
      IS_ALLOW_AUTO_EXTEND_SESSION: true
    },
    DATES: {
      IS_24_HOURS_FORMAT: true
    },
    CHATROOM: {
      IS_SHOW_MESSAGE_ID: true,
      IS_FLASH_ANIMATION: true,
      IS_ENABLE_ATTACH: true,
      IS_ENABLE_SEARCH: true,
      IS_ENABLE_IMPORTANT: true,
      IS_ENABLE_SMS: true,
      INPUT_TEXTAREA_MAX_HEIGHT: 200,
      IS_AUTO_ADJUST_HEIGHT: false,
      IS_ACK_TO_READ: true,
      IS_SHOW_UNREAD_INFO: true,
      CLICK_TO_SEND_ONLY: true,
      IS_ALWAYS_ALLOW_DOWNLOAD: true,
    },
    WATERMARK: {
      IS_DRAW_ON_IMAGE: true
    }
  }
  
};

