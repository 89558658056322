import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AccountManagerService } from '../services/account/account-manager.service';
import { UserContact } from '../../models/user-contact';
import { UserConstant } from '../../constants/user.constant';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';
import { UserContactService } from '../services/data/user-contact/user-contact.service';

import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateManagerService } from '../../utilities/translate/translate-manager.service';
import { Subscription } from 'rxjs';

import { TnDateFormatConfiguration } from '../../utilities/date/date';
import getHours from 'date-fns/get_hours'
import setHours from 'date-fns/set_hours'
import getMinutes from 'date-fns/get_minutes'
import setMinutes from 'date-fns/set_minutes'
import addYears from 'date-fns/add_years'
import isDate from 'date-fns/is_date'
import startOfDay from 'date-fns/start_of_day'
import endOfDay from 'date-fns/end_of_day'
import getSeconds from 'date-fns/get_seconds'
import setSeconds from 'date-fns/set_seconds'

import _ from 'lodash';
import { LoggerService } from '../../utilities/logger/logger.service';
import { WebclientService } from '../webclient.service';

interface Group {
  value: string,
  viewValue: string;
}

@Component({
  selector: 'tn-out-of-office',
  templateUrl: './out-of-office.component.html',
  styleUrls: ['./out-of-office.component.scss']
})
export class OutOfOfficeComponent implements OnInit {

  loggedInUser: UserContact;

  isEnableOutOfOfficeScheduler: boolean = false;
  // private enableOofSchedulerSub: Subscription;

  isOutOfOffice: boolean = false;
  outOfOfficeExpiredTimestamp: number = 0;

  isSelectingDate: boolean = false;
  minDate: Date = null;
  dateObj: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;
  isDateValid: boolean = false;

  group: any;
  groupOptions: Array<string> = [];

  // startDate: Date = new Date();
  // endDate: Date = new Date();
  startDate: Date = null;
  
  endDate: Date = null;
  isStartDateValid: boolean = false
  isEndDateValid: boolean = false
  isDateTimeValid: boolean = false

  isStartTimeValid: boolean = true
  isEndTimeValid: boolean = true
  
  isAllDay: boolean = false

  defaultDateRange: Date[];
  outOfOfficeDateRange: Date[] = [null, null];

  private selfContactPresenceSub: Subscription;
  maxDate: Date;
  dateCustomClasses: DatepickerDateCustomClasses[];

  currentLang: string = 'en';
  meridians: string[] = [TnDateFormatConfiguration.AM_EN, TnDateFormatConfiguration.PM_EN]

  constructor(
    public dialogRef: MatDialogRef<OutOfOfficeComponent>,
    private localeService: BsLocaleService,
    private _translateManagerService: TranslateManagerService,
    private _accountManagerService: AccountManagerService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService,
    private _loggerService: LoggerService,
    private _webclientService: WebclientService
  ) { 
    this.currentLang = this._translateManagerService.getCurrentLangKey();
    if (this.currentLang === 'zh-cht' || this.currentLang === 'zh-chs') {
      switch (this.currentLang) {
        case 'zh-cht':
          this.meridians = [TnDateFormatConfiguration.AM_ZH_CHT, TnDateFormatConfiguration.PM_ZH_CHT]    
          break;
      
        case 'zh-chs':
          this.meridians = [TnDateFormatConfiguration.AM_ZH_CHS, TnDateFormatConfiguration.PM_ZH_CHS]
          break;
      }

      this.currentLang = 'zh';
    }
    this.localeService.use(this.currentLang); // apply locale to calendar

    this.minDate = new Date();
    this.maxDate = new Date(addYears(this.minDate, 100))

    this.dateInputConfig = Object.assign({}, { 
      // containerClass: "theme-default",
      customTodayClass: 'custom-today-class',
      containerClass: "theme-dark-blue",
      dateInputFormat: "DD MMM YYYY",
      displayOneMonthRange: true,
      selectFromOtherMonth: true
    });
 
    // this.dateCustomClasses = [
    //   { date: this.today, classes: ['bg-warning'] }
    // ];
  }

  ngOnInit() {
    this.isEnableOutOfOfficeScheduler = this._webclientService.enable_out_of_office;
    this.dialogRef.updateSize('770px');

    this.selfContactPresenceSub = this._accountManagerService.selfContactPresence$.subscribe(user => {
      this.loggedInUser = user;
      this.checkUserOutOfOfficeState();
    });

    /* update checking for flag of enabling oof scheduler */
    // this.enableOofSchedulerSub = this._accountManagerService.selfContactPresence$.subscribe(isEnableOofScheduler => {
    //   this.isEnableOutOfOfficeScheduler = this._webclientService.out_of_office;
    // });
    // this.defaultDateRange = this.outOfOfficeDateRange = [this.today, this.today];
  }

  ngOnDestroy() {
    this.selfContactPresenceSub.unsubscribe();
    // this.enableOofSchedulerSub.unsubscribe();
  }

  checkUserOutOfOfficeState(): void {
    // console.log('checkUserOutOfOfficeState', this.loggedInUser);
    if (!this.loggedInUser) {
      return;
    }

    let { user_state, out_of_office } = this.loggedInUser;

    /* disable oof scheduler */
    if (!this.isEnableOutOfOfficeScheduler) {
      if (user_state.user_state == UserConstant.USER_STATE.OUT_OF_OFFICE || !_.isEmpty(out_of_office)) {
        this.isOutOfOffice = true;
        this.outOfOfficeExpiredTimestamp = user_state.expired_at;
      } else {
        this.isOutOfOffice = false;
        this.outOfOfficeExpiredTimestamp = 0;
      }

      this.dateInputConfig = Object.assign({}, { 
        containerClass: "theme-default",
        dateInputFormat: "YYYY-MM-DD"
      });

      return;
    }

    /* enable oof scheduler */
    // let { user_state, out_of_office } = this.loggedInUser;

    this.resetDateSelection()

    if (out_of_office) {
      let { start_at, expired_at } = out_of_office

      let start = this._timestampService.secondToDateObj(start_at)
      let end = this._timestampService.secondToDateObj(expired_at)

      if (this.dateIsValid(start) && this.dateIsValid(end)) {
        this.isOutOfOffice = true;
        this.defaultDateRange = [start, end] // this will trigger function onDateRangeChange()
      }
      
    } else {
      this.isOutOfOffice = false;
      // this.outOfOfficeExpiredTimestamp = 0;
    }
  }

  toggle(): void {
    if (this.isSelectingDate) {
      return;
    }

    if (this.isOutOfOffice) {
      this.updateUserState(false);
    } else {
      this.isSelectingDate = true;
    }
  }

  onDateChange(newVal): void {
    this.dateObj = newVal;
    this.checkDateInput();
  }

  onTimeChange(newVal): void {
    this.checkDateInput(); 
  }

  checkDateInput(): void {
    let date = this._timestampService.dateObjToSecondString(this.dateObj);
    if (!this._timestampService.checkIfTimeAfterToday(date)) {
      this.isDateValid = false;
    } else {
      this.isDateValid = true;
    }
  }

  get dateTimeLocaleDisplay(): string {
    return this.currentLang === 'en' ? 'OUT_OF_OFFICE_DATE' : 'OUT_OF_OFFICE_ZH_DATE'
  }

  resetDateSelection(): void {
    this.defaultDateRange = this.outOfOfficeDateRange = [null, null];
    this.startDate = this.endDate = null;
    this.isAllDay = false
  }

  /**
   * function aims to calculate the start time & end time for displaying
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  toggleIsAllDay(): void {
    let now = new Date()
    let [sd, ed] = this.outOfOfficeDateRange

    let start_d = sd || now
    let end_d = ed || now

    if (this.isAllDay) {
      this.startDate = startOfDay(start_d)
      this.endDate = endOfDay(end_d)
    } else {
      if (this.startDate && this.endDate) {
        // reset the current hour & minute to the start time & end time
        let now_h = getHours(this.startDate)
        let now_m = getMinutes(this.startDate)

        start_d = setHours(start_d, now_h)
        start_d = setMinutes(start_d, now_m)

        now_h = getHours(this.endDate)
        now_m = getMinutes(this.endDate)

        end_d = setHours(end_d, now_h)
        end_d = setMinutes(end_d, now_m)
      }

      this.startDate = start_d
      this.endDate = end_d
    }

    this.checkTimeInput()
  }

  dateIsValid(date: Date) {
    return date instanceof Date && !isNaN(date.getTime());
  }

  onDateRangeChange(dateRange): void {
    if (!_.isArray(dateRange)) {
      return;
    }

    let [start, end] = dateRange
    if (isDate(start) && isDate(end)) {
      this.outOfOfficeDateRange = dateRange // for displaying
      // console.log(this.outOfOfficeDateRange)
      this.toggleIsAllDay()
    }
  }

  checkIfStartTimeValid(event: boolean): void {
    this.isStartTimeValid = event;
    this.checkTimeInput()
  }

  checkIfEndTimeValid(event: boolean): void {
    this.isEndTimeValid = event;
    this.checkTimeInput()
  }

  checkTimeInput(): void {
    if (!this.isStartTimeValid || !this.isEndTimeValid) {
      this.isDateTimeValid = false;
      return
    }

    let start_date = this._timestampService.dateObjToSecondString(this.startDate);
    let end_date = this._timestampService.dateObjToSecondString(this.endDate);

    let isTimeRangeAfterStartDate = this._timestampService.checkIfTimeAfterToday(start_date)
    let isTimeRangeInCorrectOrder = this._timestampService.checkIfTimeCorrectOrder(start_date, end_date)

    if (isTimeRangeAfterStartDate && isTimeRangeInCorrectOrder) {
      this.isDateTimeValid = true
    } else {
      this.isDateTimeValid = false
    }
  }

  delete(): void {
    this.updateUserState(false);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  checkInput(): boolean {
    if (!this.isStartTimeValid || !this.isEndTimeValid) {
      return false
    }

    if (!this.dateIsValid(this.startDate) || !this.dateIsValid(this.endDate)) {
      return false
    }

    return true
  }

  confirmScheduler(): void {
    // Check input
    if (!this.checkInput()) {
      return
    }

    let start_date = this._timestampService.dateObjToSecondString(this.startDate);
    let end_date = this._timestampService.dateObjToSecondString(this.endDate);

    // Update
    this.updateUserState(
      true, 
      { startAt: start_date, expiredAt: end_date }
    );
  }

  confirm(): void {
    // Check input
    if (!this.isDateValid) {
      return;
    }

    let start_date = this._timestampService.dateObjToSecondString(new Date());
    let end_date = this._timestampService.dateObjToSecondString(this.dateObj);
    // Update
    this.updateUserState(true, { startAt: start_date, expiredAt: end_date });
    this.isSelectingDate = false;
    this.dateObj = null;
  }

  updateUserState(isOutOfOffice: boolean, dateRange?: any): void {
    let state = isOutOfOffice ? UserConstant.USER_STATE.OUT_OF_OFFICE : UserConstant.USER_STATE.NORMAL;
    this._userContactService.updateSelfUserState(state, dateRange);
  }

}
