<div class="notification-cell-wrapper cronjob-notification"
  [ngClass]="{'clickable': body.job.status == cronjobStatus.FINISHED}"
  (click)="onClick()">
  <div class="icon-container">
    <div class="icon-wrapper icon-cronjob">
      <i class="fa fa-fw fa-clock-o icon"></i>
    </div>
  </div>
  <div class="info-wrapper">
    <div class="title text-color-primary">
      <ng-container *ngIf="body.jobTitle">
        {{body.jobTitle}}
      </ng-container>
      <ng-container *ngIf="body.jobTitleTranslateKey">
        {{body.jobTitleTranslateKey | translate}}
      </ng-container>
    </div>
    <div class="content">
      <ng-container *ngIf="body.jobType == cronjobType.EXPORT_CHAT_MESSAGE">
        <div>
          {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.CHAT" | translate}} {{body.jobInfo.chatName}}
        </div>
        <div>
          {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.TIME" | translate}} {{body.jobInfo.timeIntervalDisplay}}
        </div>
      </ng-container>
      <ng-container *ngIf="body.jobType == cronjobType.EXPORT_REPORT_JOB">
        <div>
          {{'WEBCLIENT.JOB_REPORT.EXPORT.TIME_PERIOD' | translate}}: {{body.jobInfo.timeIntervalDisplay}}
        </div>
        <div>
          {{'WEBCLIENT.JOB_REPORT.NEW_REPORT.STORE' | translate}}:
          <ng-container *ngIf="body.jobInfo.storeName">{{body.jobInfo.storeName}}</ng-container>
          <ng-container *ngIf="body.jobInfo.storeTranslateKey">{{body.jobInfo.storeTranslateKey | translate}}</ng-container>
        </div>
        <div>
          {{'WEBCLIENT.JOB_REPORT.NEW_REPORT.TYPE' | translate}}:
          <ng-container *ngIf="body.jobInfo.typeName">{{body.jobInfo.typeName}}</ng-container>
          <ng-container *ngIf="body.jobInfo.typeTranslateKey">{{body.jobInfo.typeTranslateKey | translate}}</ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="body.jobType == cronjobType.EXPORT_FORM">
        <div>
          {{'WEBCLIENT.WORKFLOW.EXPORT.TIME_PERIOD' | translate}}: {{body.jobInfo.timeIntervalDisplay}}
        </div>
        <div>
          {{'WEBCLIENT.WORKFLOW.FILTER.TEAM' | translate}}:
          <ng-container *ngIf="body.jobInfo.teamName">{{body.jobInfo.teamName}}</ng-container>
          <ng-container *ngIf="body.jobInfo.teamTranslateKey">{{body.jobInfo.teamTranslateKey | translate}}</ng-container>
        </div>
        <div>
          {{'WEBCLIENT.WORKFLOW.FILTER.TYPE' | translate}}:
          <ng-container *ngIf="body.jobInfo.typeName">{{body.jobInfo.typeName}}</ng-container>
          <ng-container *ngIf="body.jobInfo.typeTranslateKey">{{body.jobInfo.typeTranslateKey | translate}}</ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="body.job.status == cronjobStatus.IN_QUEUE">
        {{"GENERAL.PROCESSING" | translate}}
      </ng-container>
      <ng-container *ngIf="body.job.status == cronjobStatus.ERROR">
        {{"GENERAL.SYSTEM_ERROR" | translate}}
      </ng-container>
      <ng-container *ngIf="body.job.status == cronjobStatus.RUNNING">
        {{body.job.progress_description}}
      </ng-container>
      <ng-container *ngIf="body.job.status == cronjobStatus.FINISHED">
        <ng-container *ngIf="body.jobType == cronjobType.EXPORT_CRITICAL_MESSAGE">
          <div class="text-color-primary">
            {{"WEBCLIENT.CHATROOM.CRITICAL_MESSAGE.EXPORT.EXPORT_FINISH_TIME" | translate}} {{getCronjobEndTimeDisplay(body.job.end_time)}}
          </div>
        </ng-container>
        {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.EXPORT_DONE_DOWNLOAD" | translate}}
      </ng-container>
    </div>
  </div>
</div>
