<div class="attachment-message">

  <ng-container [ngSwitch]="type">

    <div class="image-message" *ngSwitchCase="ATTACHMENT_TYPE.IMAGE">
      <div class="image-preview"
        (click)="onClick($event, true)"
        [ngClass]="{'clickable': isClickable}" 
        [ngStyle]="{'background-image': imgSrc}">
        <div class="gif-preview-icon" *ngIf="isGif">GIF</div>
      </div>
      <div class="image-caption" 
        *ngIf="body.message"
        (click)="onClick($event, false)">
        <tn-text-message [body]="body"></tn-text-message>
      </div>
    </div>

    <div class="audio-message" 
      (click)="onAudioClick($event)"
      [ngClass]="{'clickable': isClickable}" 
      *ngSwitchCase="ATTACHMENT_TYPE.AUDIO">
      <div class="audio-play-icon-wrapper">
        <div (click)="onClick($event, true)" class="audio-play-pause-icon" 
          [ngClass]="{
            'audio-play': !isPlayingAudio, 
            'audio-pause': isPlayingAudio
          }">
        </div>
      </div>
      <div class="audio-duration">
        {{audioDuration}}
      </div>
      <div class="audio-voice-icon-wrapper">
        <div class="audio-voice-icon audio-voice-default" *ngIf="!isPlayingAudio"></div>
        <div class="audio-voice-icon"
          [ngClass]="{
            'audio-voice-play1': (currentAudio.currentDuration % 3 >= 0 && currentAudio.currentDuration % 3 < 1),
            'audio-voice-play2': (currentAudio.currentDuration % 3 >= 1 && currentAudio.currentDuration % 3 < 2),
            'audio-voice-play3': (currentAudio.currentDuration % 3 >= 2 && currentAudio.currentDuration % 3 < 3)
          }"
          *ngIf="isPlayingAudio">
        </div>
      </div>
    </div>

    <div class="video-message" 
      (click)="onClick($event, true)"
      [ngClass]="{'clickable': isClickable}" 
      *ngSwitchCase="ATTACHMENT_TYPE.VIDEO">
      <div class="image-preview" [ngStyle]="{'background-image': imgSrc}">
        <div class="video-play-icon fa fa-play-circle-o fa-4x"></div>
      </div>
    </div>

    <div class="file-message" 
      (click)="onClick($event, true)"
      [ngClass]="{'clickable': isClickable}" 
      *ngSwitchDefault>
      <div class="file-icon-wrapper">
        <ng-container [ngSwitch]="getDocFileType" *ngIf="attachment">
          <div class="file-icon">
            <div
              [ngClass]="{ 'doc-file-white-icon': isSentByMe, 'doc-file-black-icon': !isSentByMe }"
              *ngSwitchCase="ATTACHMENT_TYPE.DOC"
            ></div>
            <div
              [ngClass]="{ 'excel-file-white-icon': isSentByMe, 'excel-file-black-icon': !isSentByMe }"
              *ngSwitchCase="ATTACHMENT_TYPE.SHEET"
            ></div>
            <div
              [ngClass]="{ 'ppt-file-white-icon': isSentByMe, 'ppt-file-black-icon': !isSentByMe }"
              *ngSwitchCase="ATTACHMENT_TYPE.PPT"
            ></div>
            <div
              [ngClass]="{ 'pdf-file-white-icon': isSentByMe, 'pdf-file-black-icon': !isSentByMe }"
              *ngSwitchCase="ATTACHMENT_TYPE.PDF"
            ></div>
            <i class="fa fa-file-o fa-3x" *ngSwitchDefault></i>
            <!-- <i class="fa fa-file-pdf-o fa-3x"
            [ngClass]="{
              'fa-file-pdf-o': type == ATTACHMENT_TYPE.PDF,
              'fa-file-o': type == ATTACHMENT_TYPE.GENERAL
            }"></i> -->
          </div>
        </ng-container>
      </div>
      <div class="file-info-wrapper">
        <div class="file-name multiline-text-display" [ngClass]="{ 'text-color-primary': !isSentByMe }">
          {{filenameDisplay}}
        </div>
        <div class="file-size">{{fileSize}}</div>  
      </div>
    </div>

  </ng-container>
</div>