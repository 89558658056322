import { ZhChtLoginStrings } from "../login/strings/zh_cht-login.strings";
import { ZhChtWebclientString } from "../webclient/strings/zh_cht-webclient.strings";
import { ZhChtCustomFieldStrings } from "../webclient/shared/custom-field/strings/zh_cht-custom-field.strings";
import { ZhChtDateStrings } from './date/zh_cht-date.strings';

export const ZhChtGeneralStrings = {
  LANGUAGE: "語言",
  TEAMNOTE: "團信",
  TERMS_AND_CONDITIONS: "服務條款",
  LOGOUT: "登出",

  POWER_BY_MSG: "Powered by TeamNote®",
  COPYRIGHT_MSG: "© 2025 TeamNote Limited. 版權所有，並保留一切權利。",

  TERMS_TITLE: "服務條款",
  TERMS_ACCEPT: "接受",
  TERMS_DECLINE: "拒絕",

  SYSTEM_ERROR: "發生錯誤，請稍後再試",
  RESOURCE_NOT_FOUND: "目標資源不存在",
  VIRUS_DETECTED_ERROR: "您嘗試上傳的檔案已被識別為含有病毒。請確保在上傳之前對您的檔案進行掃描以檢查是否有惡意軟體。",

  FILE_TIP: "支援格式: JPEG, JPG, PNG, PDF, DOC, XLS, PPT, MP4; 大小限制: {{SIZE}}MB",
  FILE_TIP_DYNAMIC: "支援格式: {{FORMATS}}; 大小限制: {{SIZE}}MB",
  FILE_TYPE_ERROR: "不支援此文件格式",
  FILE_SIZE_ERROR: "文件大小超過限制",
  NO_FILE: "請上載最少一個有效的文件",
  SELECT_FILE: "選擇檔案",
  SELECT_IMAGE: "選擇圖片",
  SIGN_HERE: "在此簽名",

  FILE_OPEN_ERROR: "無法打開文件",
  FILE_PDF_PASSWORD: "此PDF受密碼保護，因此無法打開",
  FILE_PDF_PASSWORD_REQUIRED: "請輸入PDF密碼",
  FILE_PDF_PASSWORD_ERROR: "錯誤的PDF密碼",

  NO_CONTENT: "沒有內容",
  NO_PHOTO: "沒有相片",
  NO_VIDEO: "沒有影片",
  NO_DOCUMENT: "沒有文件",
  NO_KEYWORDS: "輸入關鍵字查找信息",

  NOTIFICATION: "通知",
  NO_NOTIFICATION: "沒有通知",
  NO_INTERACTION_ALERT: "需要使用者互動才能啟用通知聲音。",
  NEW_MESSAGE_NOTIFICATION: "新信息: {{CHAT}}",
  NEW_CRITICAL_MESSAGE_NOTIFICATION: "你有重要訊息",

  NEW_VERSION_MSG: "新版本已推出。請重新整理此頁面。",
  SESSION_TIMEOUT_MSG: "您的使用期限已過，請重新登入。",
  
  DONE: "完成",
  EDIT: "編輯",
  DELETE: "刪除",
  CANCEL: "取消",
  SAVE: "儲存",
  BACK: "返回",
  CONFIRM: "確定",
  UPLOAD: "上載",
  EXPORT: "導出",
  IMPORT: "導入",
  DOWNLOAD: "下載",
  BROWSE_CAPS: "瀏覧",
  REFRESH: "重新整理",
  ALL: "全部",
  SUBMIT: "提交",
  CLEAR: "清除",
  OR: "或",
  SEARCH: "搜尋",

  START: '開始',
  END: '結束',
  FROM: "由",
  TO: "至",
  PROCESSING: "處理中...",

  WEBSOCKET: {
    OFFLINE: '你已經處於離線狀態, 1秒後重連...',
  },

  DOWNLOADED_TIP: '文檔已下載'
};

export const ZhChtLoadingStrings = {
  LOADING: "載入中...",
  CONNECTING: "連接中...",
  RECONNECTING: "重新連接中...",
  LOADING_CONTACTS: "載入通訊錄中..."
};

export const ZhChtUtilityStrings = {
  IMAGE_CAPTION_PLACEHOLDER: "添加照片描述...",
  FILE_UPLOAD_DRAG_FILE: "拖曳文件至此",

  CUSTOM_FIELD: ZhChtCustomFieldStrings
};

export const ZhChtLangStrings = {
  CANTONESE: "粵語",
  MANDARIN: "普通話"
};

export const ZhChtString = {
  GENERAL: ZhChtGeneralStrings,
  LOGIN: ZhChtLoginStrings,
  LOADING: ZhChtLoadingStrings,
  UTILITY: ZhChtUtilityStrings,
  LANG: ZhChtLangStrings,
  DATE: ZhChtDateStrings,
  
  WEBCLIENT: ZhChtWebclientString
}
