import { Component, OnInit, Input } from '@angular/core';
import { CronjobTracker, CronJobStatus, CronJobType, ExportAttachmenetCronJobResult, CronjobExportChatMessage } from '../../../cronjob-tracker/models/cronjob';
import { NotificationCenterService } from '../../services/notification-center.service';
import { FileManagerService } from '../../../file-manager/file-manager.service';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';

@Component({
  selector: 'tn-cronjob-notification',
  templateUrl: './cronjob-notification.component.html',
  styleUrls: ['./cronjob-notification.component.scss']
})
export class CronjobNotificationComponent implements OnInit {

  @Input() body: CronjobTracker;

  cronjobStatus = CronJobStatus;
  cronjobType = CronJobType;


  constructor(
    private _notificationCenterService: NotificationCenterService,
    private _fileManagerService: FileManagerService,
    private _timestampService: TimestampService,
  ) { }

  ngOnInit() {
    
  }

  onClick(): void {
    if (this.body.job.status == this.cronjobStatus.FINISHED) {
      this.download();
    }
  }

  getCronjobEndTimeDisplay(timestamp: string) {
    return this._timestampService.getDateTimeDisplay(timestamp);
  }

  download(): void {
    // Only try to download if filename is specified
    if (!this.body.jobInfo.fileName) {
      return;
    }
    let downloadResult: ExportAttachmenetCronJobResult = JSON.parse(this.body.job.result);

    let file_name = this.body.jobInfo.fileName;

    if (this.body.jobInfo.exportType === this.cronjobType.EXPORT_CRITICAL_MESSAGE) {
      let exportTimestamp = this.getCronjobEndTimeDisplay(this.body.job.end_time);
      const [ date, time ] = exportTimestamp.split(' ');
      const formattedTime = time.split(':').join('-')
      file_name += `-${[date, formattedTime].join('_')}`;
    }

    this._fileManagerService.downloadFileByAttachmentId(downloadResult.attachment_id, file_name);

    this._notificationCenterService.closeNotificationCenter();
  }
}
