/**
 *
 * All 'chat' variants also belongs to 'chat' (i.e. News Channel, News Category)
 * 
 */

import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Chat } from '../../../../models/chat';
import { Message } from '../../../../models/message';

import { UserContactService } from '../user-contact/user-contact.service';
import { ChatMessageService } from '../messages/chat-message/chat-message.service';
import { AccountManagerService } from '../../account/account-manager.service';
import { SocketService } from '../../socket/socket.service';

import * as _ from 'lodash';
import { LocalStorageManagerService } from '../../../../utilities/local-storage/local-storage-manager.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../utilities/logger/logger.service';
import { PresenceTypeConstant } from '../../../../constants/presence-type.constant';
import { PageUrlConstant } from '../../../../constants/page-url.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../../../constants/local-storage-key.constant';
import { AMQPRoutingKey } from '../../../../constants/amqp-routing-key.constant';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';
import { NewsMessageService } from '../messages/news-message/news-message.service';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';
import { ChatConstant } from '../../../../constants/chat.constant';
import {TnNotificationService} from '../../../../utilities/tn-notification/tn-notification.service';
import { WebclientService } from '../../../../webclient/webclient.service';

interface Chats {
  [chatId: string]: Chat;
}

@Injectable()
export class ChatService {
  chats: Chats = {};
  chats$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>([]);

  // for passing target chat from contact list to chat room
  activeChat: Chat = null;
  activeChat$: BehaviorSubject<Chat> = new BehaviorSubject<Chat>(null);

  // for starred messages
  targetMessage: Message = null;
  targetMessage$: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);

  CHAT_ROOM_TYPES = [
    PresenceTypeConstant.INDIVIDUAL_CHAT,
    PresenceTypeConstant.GROUP_CHAT,
    PresenceTypeConstant.GROUP_BROADCAST
  ];
  NEWS_CHAT_TYPES = [
    PresenceTypeConstant.NEWS_CHANNEL,
    PresenceTypeConstant.NEWS_CATEGORY
  ];

  constructor(
    private _chatMessageService: ChatMessageService,
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService,
    private _socketService: SocketService,
    private _fileManagerService: FileManagerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _router: Router,
    private _loggerService: LoggerService,
    private _newsMessageService: NewsMessageService,
    private _tnNotificationService: TnNotificationService,
    private _timestampService: TimestampService,
    private injector: Injector,
  ) { }

  initChats(): void {
    this._loggerService.debug("Reset chats & activeChat");
    this.chats = {};
    this.updateChatSubject();
    this.activeChat = null;
    this.updateActiveChatSubject(null);
  }

  receiveChatPresence(c: Chat, correlationId?: string): void {
    switch (c.t) {
      case PresenceTypeConstant.INDIVIDUAL_CHAT:
      case PresenceTypeConstant.GROUP_CHAT:
      case PresenceTypeConstant.GROUP_BROADCAST:
        this.insertOrUpdateChat(c);
        break;
      case PresenceTypeConstant.NEWS_CHANNEL:
      case PresenceTypeConstant.NEWS_CATEGORY:
        this.insertOrUpdateChat(c);
        this._newsMessageService.insertOrUpdateNewsChat(c, correlationId);
        break;
      case PresenceTypeConstant.CHAT_ABSENCE:
        // Chat
        this.deleteChatByChatId(c.chat_id);
        this._chatMessageService.removeUnreadMessageUnderChat(c.chat_id);
        this._chatMessageService.updateUnreadMessageSubject();
        // News
        if (c.chat_id.indexOf("news") != -1) {
          // update news subject to trigger re-rendering
          this._newsMessageService.deleteNewsChatByChatId(c.chat_id);
        }
        break;
      case PresenceTypeConstant.MUTE_CHAT:
        this.updateChatMuteStatus(c.chat_id, true, c.expired_at);
        break;
      case PresenceTypeConstant.MUTE_CHAT_ABSENSE:
        this.updateChatMuteStatus(c.chat_id, false);
        break;
    }
  }
  updateChatSubject(): void {
    this.chats$.next(_.toArray(this.chats));
  }

  restoreActiveChatByChatId(chatId: string): void {
    let chat = this.getChatByChatId(chatId);
    if (chat) {
      if (_.every(chat.members, (uid) => uid === this._accountManagerService.userId)) {
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);
        return;
      }

      this._loggerService.debug("Restoring activeChat from previous session: " + chatId);
      this.updateActiveChatSubject(chat);
    }
  }

  updateActiveChatSubject(chat: Chat): void {
    this.activeChat = chat;
    if (chat) {
      this.updateChatIsVisibleState(chat.chat_id, true);
    }
    this.activeChat$.next(this.activeChat);
  }
  goToChatPage(): void {
    this._loggerService.log("Redirecting to /webclient/chat");
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.CHAT]);
  }

  insertOrUpdateChat(c: Chat): void {
    if (!c) {
      return;
    }
    c = this.prepareChatForDisplay(c);
    if (this.chats[c.chat_id]) {
      this.chats[c.chat_id] = _.assign(this.chats[c.chat_id], c);
    } else {
      c.newMessageCount = 0;
      c.isVisible = this.checkIfChatIsVisible(c.chat_id);
      c.isFavourite = this.checkIfChatIsFavourited(c.chat_id);
      c.isArchived = this.checkIfChatIsArchived(c.chat_id);
      c.isLoadedHistory = false;
      this.chats[c.chat_id] = c;

      // If it is indivual chat, check if it is duplicated, if yes, set older chats to be non visible and only show newest chat
      if (c.t == PresenceTypeConstant.INDIVIDUAL_CHAT) {
        const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency

        let chatTargetId = _.toString(_.without(c.members, this._accountManagerService.userId));
        let chatWithSameMembers = _.filter(this.chats, (chat) => {
          if (chat.t != PresenceTypeConstant.INDIVIDUAL_CHAT) {
            return false;
          }
          let targetId = _.toString(_.without(chat.members, this._accountManagerService.userId));
          return chatTargetId == targetId;
        });
        if (chatWithSameMembers.length > 1) {
          chatWithSameMembers = _.sortBy(chatWithSameMembers, "create_date");
          _.each(chatWithSameMembers, (chat) => {
            chat.isVisible = false;
          });
          let newestChat = _.last(chatWithSameMembers);
          newestChat.isVisible = true;
        }
      }
    }
    if (this.activeChat && this.activeChat.chat_id == c.chat_id) {
      this.updateActiveChatSubject(c);
    }
    this._userContactService.getMissingContacts(c.members);
  }
  deleteChatByChatId(chatId: string): void {
    delete this.chats[chatId];
    if (this.activeChat && this.activeChat.chat_id == chatId) {
      this.updateActiveChatSubject(null);
    }
  }
  updateChatMuteStatus(chatId: string, isMuted: boolean, muteExpiredAt?: number): void {
    let c = this.getChatByChatId(chatId);
    if (c) {
      this._loggerService.debug(`
        Updating chat mute status:\n
        Chat id: ` + chatId + `\n
        isMuted: ` + isMuted + `\n
        muteExpiredAt: ` + muteExpiredAt);
      c.isMuted = isMuted;
      c.muteExpiredAt = muteExpiredAt;
      if (isMuted) {
        this.checkIfChatIsStillMuted(chatId);
      }
    }
  }

  checkIfChatIsStillMuted(chatId: string): void {
    let c = this.getChatByChatId(chatId);
    if (c) {
      if (c.isMuted) {
        if (this._timestampService.checkIfTimeBeforeToday(c.muteExpiredAt)) {
          // expire already, no longer muted.
          c.isMuted = false;
        }
      }
    }
  }

  // Favourite
  setFavouriteChatCookies() {
    let chatIds = _.map(this.getFavouritedChats(), (c) => {
      return c.chat_id;
    });
    // this._loggerService.debug("Set favourite chat cookeis to \n" + chatIds);
    // this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.FAVOURITE_CHAT, chatIds);

    this._loggerService.debug("Set favourite chat localStorage to \n" + chatIds);
    this._localStorageManagerService.setLocalStorageByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.FAVOURITE_CHAT, chatIds);
  }
  checkIfChatIsFavourited(chatId: string): boolean {
    // let all = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.FAVOURITE_CHAT);
    let all = this._localStorageManagerService.getLocalStorageByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.FAVOURITE_CHAT);

    let result;
    if (!all) {
      result = false;
    } else {
      result = _.indexOf(all.split(','), chatId) !== -1;
    }
    return result;
  }
  updateChatIsFavouriteState(chatId: string, isFavourite: boolean): void {
    let c = this.getChatByChatId(chatId);
    if (c) {
      c.isFavourite = isFavourite;
      this._loggerService.debug("Set chat.isFavourite\nchat id: " + chatId + "\nisFavourite: " + isFavourite);
    }
    this.setFavouriteChatCookies();
    this.updateChatSubject();
  }

  // Archive
  setArchivedChatCookies() {
    let chatIds = _.map(this.getArchivedChats(), (c) => {
      return c.chat_id;
    });
    // this._loggerService.debug("Set archived chat cookeis to \n" + chatIds);
    // this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ARCHIVED_CHAT, chatIds);

    this._loggerService.debug("Set archived chat localStorage to \n" + chatIds);
    this._localStorageManagerService.setLocalStorageByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ARCHIVED_CHAT, chatIds);
  }
  checkIfChatIsArchived(chatId: string): boolean {
    // let all = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ARCHIVED_CHAT);
    let all = this._localStorageManagerService.getLocalStorageByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ARCHIVED_CHAT);
    let result;
    if (!all) {
      result = false;
    } else {
      result = _.indexOf(all.split(','), chatId) !== -1;
    }
    return result;
  }
  updateChatIsArchivedState(chatId: string, isArchived: boolean): void {
    let c = this.getChatByChatId(chatId);
    if (c) {
      c.isArchived = isArchived;
      this._loggerService.debug("Set chat.isArchived\nchat id: " + chatId + "\nisArchived: " + isArchived);
    }
    this.setArchivedChatCookies();
    this.updateChatSubject();
  }

  // Is visible (hidden chat)
  setNonVisibleChatCookies() {
    let chatIds = _.map(this.getNonVisibleChats(), (c) => {
      return c.chat_id;
    });
    this._loggerService.debug("Set non visible chat cookeis to \n" + chatIds);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.NON_VISIBLE_CHAT, chatIds);
  }
  checkIfChatIsVisible(chatId: string): boolean {
    let all = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.NON_VISIBLE_CHAT);
    let result;
    if (!all) {
      result = true;
    } else {
      result = _.indexOf(all.split(','), chatId) === -1;
    }
    return result;
  }
  updateChatIsVisibleState(chatId: string, isVisible: boolean): void {
    let c = this.getChatByChatId(chatId);
    if (c) {
      c.isVisible = isVisible;
      this._loggerService.debug("Set chat.isVisible\nchat id: " + chatId + "\isVisible: " + isVisible);
    }
    if (!isVisible) {
      // if this chat is the current chat room and got removed, clear chat room
      if (this.activeChat && chatId == this.activeChat.chat_id) {
        this._loggerService.debug("Current chat room turns to non visible chat, clear active chat room subject");
        this.updateActiveChatSubject(null);
      }
    }
    this.setNonVisibleChatCookies();
    this.updateChatSubject();
  }

  getAllChatIds(): string[] {
    let c = _.map(this.chats, (c, chatId) => {
      return chatId;
    });
    return c;
  }
  getChatByChatId(chatId: string): Chat {
    return this.chats[chatId];
  }

  getSelfChat(): Chat[] {
    let ics = this.getIndividualChats();
    
    const selfChat = _.filter(ics, (ic) => _.every(ic.members, (uid) => uid === this._accountManagerService.userId));
    return selfChat;
  }
  checkIfChatIsChatRoomByChatId(chatId: string): boolean {
    let c = this.getChatByChatId(chatId);
    if (!c) {
      return false;
    }
    return _.indexOf(this.CHAT_ROOM_TYPES, c.t) !== -1;
  }
  getChatRoomByChatId(chatId: string): Chat {
    let c = this.getChatByChatId(chatId);
    if (this.checkIfChatIsChatRoomByChatId(chatId)) {
      return c;
    } else {
      return null;
    }
  }
  getNewsChannelOrCategoryByChatId(chatId: string): Chat {
    let c = this.getChatByChatId(chatId);
    if (c && _.indexOf(this.NEWS_CHAT_TYPES, c.t) !== -1) {
      return c;
    } else {
      return null;
    }
  }

  getChatsForTimeChatList(): Chat[] {
    let ic = this.getIndividualChats();
    let gc = this.getGroupChats();
    let ac = this.getAnnouncementChats();
    let all =  _.union(ic, gc, ac);
    all = _.filter(all, (c) => {
      return c.displayName !== null;
    });
    return all;
  }
  getAllGroupChats(): Chat[] {
    let gc = _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_CHAT});
    let ac = _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_BROADCAST});
    return _.union(gc, ac);
  }
  getFavouritedChats(): Chat[] {
    return _.filter(this.chats, {'isFavourite': true, 'isVisible': true});
  }
  getArchivedChats(): Chat[] {
    return _.filter(this.chats, {'isArchived': true, 'isVisible': true});
  }
  getNonVisibleChats(): Chat[] {
    return _.filter(this.chats, {'isVisible': false});
  }
  getIndividualChats(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.INDIVIDUAL_CHAT, 'isFavourite': false, 'isArchived': false, 'isVisible': true});
  }
  getGroupChats(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_CHAT, 'isFavourite': false, 'isArchived': false, 'isVisible': true});
  }
  getAllNormalGroupChats(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_CHAT});
  }
  getAllAnnouncementGroupChats(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_BROADCAST});
  }
  getAnnouncementChats(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.GROUP_BROADCAST, 'isFavourite': false, 'isArchived': false, 'isVisible': true});
  }
  getNewsChannels(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.NEWS_CHANNEL});
  }
  getNewsCategories(): Chat[] {
    return _.filter(this.chats, {'t': PresenceTypeConstant.NEWS_CATEGORY});
  }

  getFavouritedChatsWithNoArchivedChats(): Chat[] {
    const allFavouritedChats = this.getFavouritedChats();
    const allArchivedChatIds = _.map(this.getArchivedChats(), 'chat_id');

    return _.filter(allFavouritedChats, (chat) => !_.includes(allArchivedChatIds, chat.chat_id));
  }

  sortChatsByName(chats: Chat[]): Chat[] {
    return _.orderBy(chats, 'name');
  }
  sortChatsByLastMessageTimeAndName(chats: Chat[]): Chat[] {
    return _.orderBy(chats, [function (f) { return f.lastMessage ? f.lastMessage.timestamp : 0; }, function(f) { return f.displayName ? f.displayName.toLowerCase() : '' }], ['desc', 'asc']);
  }

  getLastMessageOfChat(chat: Chat): Message {
    return this._chatMessageService.getLastMessageOfChat(chat.chat_id);
  }

  getTargetOfChat(chat: Chat): any {
    if (chat.name) {
      return {
        name: chat.name,
        pic: chat.pic
      };
    } else {
      let chatTargetId = _.cloneDeep(chat.members);

      const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency
      if (_webclientService.allow_self_message) {
        chatTargetId = _.without(chat.members, this._accountManagerService.userId);

        // invidual chat for me
        if (_.every(chat.members, (uid) => uid === this._accountManagerService.userId)) {
          chatTargetId = [this._accountManagerService.userId];
        }
      } else {
        chatTargetId = _.without(chat.members, this._accountManagerService.userId);
      }

      if (chatTargetId) {
        let chatTarget = this._userContactService.getUserContactByUserId(_.toString(chatTargetId));
        return chatTarget ? chatTarget : {name: null, pic: ''};
      }
    }
  }
  getIndividualChatByUserId(userId: string): Chat {
    const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency

    let ic = _.filter(this.chats, {'t': PresenceTypeConstant.INDIVIDUAL_CHAT});
    ic = _.orderBy(ic, ['create_date'], ['desc'])
    for (var i = 0; i < ic.length; i++) {
      if (_webclientService.allow_self_message) {
        if (userId !== this._accountManagerService.userId) {
          if (_.indexOf(ic[i].members, userId) !== -1) {
            return ic[i];
          }
        } else {
          if (ic[i]?.chatTarget.user_id === this._accountManagerService.userId) {
            return ic[i];
          }
        }
      }
    }

    return null;
  }
  getChatDisplayName(chat: Chat): string {
    if (chat.t == PresenceTypeConstant.INDIVIDUAL_CHAT) {
      let target = this.getTargetOfChat(chat);
      return target.name;
    } else {
      return chat.name;
    }
  }
  getChatDisplayLastMessage(chat: Chat): any {
    let msg = this.getLastMessageOfChat(chat);
    return msg;
  }
  getChatUnreadCount(chat: Chat): number {
    return this._chatMessageService.getUnreadCountOfChat(chat.chat_id);
  }

  prepareChatForDisplay(chat: Chat): Chat {
    chat.displayName = this.getChatDisplayName(chat);
    if (!chat.displayName) {
      return chat;
    }

    chat.newMessageCount = this.getChatUnreadCount(chat);
    chat.lastMessage = _.clone(this.getChatDisplayLastMessage(chat));

    if (!chat.lastMessage) {
      chat.lastMessage = chat.cacheLastMessage;
    } else {
      chat.cacheLastMessage = chat.lastMessage;
      if (chat.security_level == ChatConstant.SECURITY_LEVEL.RESTRICTED) {
        chat.cacheLastMessage.body = null;
        chat.cacheLastMessage.parsedBody = {};
      }
    }
    chat.isGroup = chat.t != PresenceTypeConstant.INDIVIDUAL_CHAT;

    if (chat.t == PresenceTypeConstant.INDIVIDUAL_CHAT) {
      chat.chatTarget = this.getTargetOfChat(chat);
    }

    return chat;
  }

  setChatIsLoadedHistory(chatId: string): void {
    let c = this.getChatByChatId(chatId);
    c.isLoadedHistory = true;
  }


  // AMQP
  declareIndividualChatByUserId(userId: string, callback: Function, doNotGoToChat?: boolean): void {
    if (!userId) {
      this._loggerService.warn("Trying to declare chat without user id, rejected.");
      return;
    }
    let correlationId = 'DECLARE_CHAT_' + userId + '_' + _.now();
    let routingKey = AMQPRoutingKey.DECLARE_USER_CHAT;
    let headers = {
      'correlation-id': correlationId,
      'uid': userId
    };
    let body = '';



    let declareChatCallBack = (frame) => {
      console.log(frame);
      let newChat = JSON.parse(frame.body)[0];
      this.receiveChatPresence(newChat);
      if (!doNotGoToChat) {
        this.updateActiveChatSubject(newChat);
      }
      callback(newChat);
    };

    this._socketService.sendMessage(routingKey, headers, body, correlationId, declareChatCallBack);

  }
  declareGroupChat(groupName: string, creator: string, publicMessage: string, pic: string, members: string, chatId: string, callback: Function, chatSecurityLevel: number): void {
    if (!groupName || !members) {
      this._loggerService.warn("Trying to declare group chat without group name / members, rejected.");
      return;
    }
    let correlationId = 'DECLARE_GROUP_CHAT' + '_' + _.now();
    let routingKey = AMQPRoutingKey.DECLARE_GROUP_CHAT;
    let headers = {
      'correlation-id': correlationId,
      'name': groupName,
      'pic': pic,
      'members': members
    };

    if (creator != null) {
      headers['creator'] = creator;
    }
    if (publicMessage != null) {
      headers['public_message'] = publicMessage;
    }
    if (chatId != null) {
      headers['chat_id'] = chatId;
    }

    if (chatSecurityLevel != null) {
      headers['security_level'] = chatSecurityLevel;
    }

    let body = '';

    let declareChatCallBack = (frame) => {
      let newChat = JSON.parse(frame.body)[0];
      this.receiveChatPresence(newChat);
      if (callback) {
        this.updateActiveChatSubject(newChat);
        callback();
      }
    };

    this._socketService.sendMessage(routingKey, headers, body, correlationId, declareChatCallBack);
  }
  createChatGroup(groupName: string, pic: string, members: string[], publicMessage: string, chatSecurityLevel: number, callback?: Function) {
    members.push(this._accountManagerService.userId);
    let membersId = _.join(_.uniq(members), ' ');
    this.declareGroupChat(groupName, null, publicMessage, pic, membersId, null, callback, chatSecurityLevel);
  }
  updateChatGroup(groupName: string, pic: string, members: string[], chatId: string) {
    members.push(this._accountManagerService.userId);
    let membersId = _.join(_.uniq(members), ' ');
    this.declareGroupChat(groupName, null, null, pic, membersId, chatId, null, null);
  }
  leaveChatGroup(chatId: string): void {
    if (!chatId) {
      this._loggerService.warn("Trying to leave chat group without chat id, rejected.");
      return;
    }
    let correlationId = 'LEAVE_CHAT_' + chatId + '_' + _.now();
    let routingKey = AMQPRoutingKey.LEAVE_CHAT;
    let headers = {
      'chat_id': chatId
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }
  kickMember(chatId: string, userId: string): void {
    if (!chatId || !userId) {
      this._loggerService.warn("Trying to kick member without chat id / user id, rejected.");
      return;
    }
    let correlationId = 'KICK_MEMBER_' + chatId + '_' + _.now();
    let routingKey = AMQPRoutingKey.KICK_MEMBER;
    let headers = {
      'chat_id': chatId,
      'uid': userId
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }
  addChatAdmin(chatId: string, userId: string): void {
    if (!chatId || !userId) {
      this._loggerService.warn("Trying to add chat admin without chat id / user id, rejected.");
      return;
    }
    let correlationId = 'CHANGE_CHAT_CREATOR_' + chatId + '_' + _.now();
    let routingKey = AMQPRoutingKey.CHANGE_CHAT_CREATOR;
    let headers = {
      'chat_id': chatId,
      'new_creator_id': userId
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }
  updateMuteChatStatusByChatId(chatId: string, newMuteStatus: boolean, ttl?: number): void {
    let routingKey = newMuteStatus ? AMQPRoutingKey.MUTE_CHAT : AMQPRoutingKey.UNMUTE_CHAT;
    let correlationId = (newMuteStatus ? "MUTE_CHAT" : "UNMUTE_CHAT") + "_" + _.now(); 
    let headers = {
      'chat_id': chatId,
      'ttl': ttl
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  updateTargetMessageSubject(message: Message): void {
    this.targetMessage = message;
    this.targetMessage$.next(this.targetMessage);
  }

  checkImportantLevelForChat(chat: Chat): number {
    let targetUserId = _.find(chat.members, (userId) => userId !== this._accountManagerService.userId)
    return this._userContactService.checkIfUserIsImportant(targetUserId)
  }
}
