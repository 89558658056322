import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Message } from '../../../../models/message';
import { TeamnoteApiService } from '../../../../api/teamnote-api.service';

import * as _ from 'lodash';
import { UserContactService } from '../../../services/data/user-contact/user-contact.service';
import { AccountManagerService } from '../../../services/account/account-manager.service';
import { InfoMessageService } from '../../../services/data/messages/info-message/info-message.service';
import { MessageReadAPI } from '../../../../models/message-read';
import { MessageAckAPI } from '../../../../models/message-acknowledgement';
import { MessageDeliveryAPI } from '../../../../models/message-delivery';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';
import { Chat } from '../../../../models/chat';
import { UserContact } from '../../../../models/user-contact';

@Component({
  selector: 'tn-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {
  message: Message;
  chat: Chat;

  reads: any[];
  deliveries: any[];

  isShowUnreads: boolean = false;
  unreads: any[];

  isShowAcks: boolean = false;
  isShowMessageAckOnly: boolean = false;
  acks: any[];

  fallbackStatus: any[];

  constructor(
    public dialogRef: MatDialogRef<MessageInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _teamnoteApiService: TeamnoteApiService,
    private _userContactService: UserContactService,
    private _accountManagerService: AccountManagerService,
    private _infoMessageService: InfoMessageService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.message = this.data.message;
    this.chat = this.data.chat;

    this.reads = [];
    this.deliveries = [];
    
    this.isShowUnreads = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_SHOW_UNREAD_INFO;
    this.unreads = [];

    this.isShowMessageAckOnly = this.data.isShowMessageAckOnly
    this.isShowAcks = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_SHOW_ACK_INFO && this.checkIfMsgIsAcknowledgeType()
    this.acks = [];

    if (!this.chat.isGroup && this.isShowMessageAckOnly) {
      this.parseMyAcknowledgement();
      return
    }
    
    this.getMessageDetail();
  }

  checkIfMsgIsAcknowledgeType(): boolean {
    if (!this.message.parsedBody.acknowledgement) {
      return false
    }

    let { mode } = this.message.parsedBody.acknowledgement

    if (mode === 'disabled') {
      return false
    }
    
    return true
  }

  getMessageDetail() {
    this._infoMessageService.getMessageDetailAPI(
      this.message.message_id,
      resp => {
        this.parseAcknowledgements(resp.acknowledges);

        if (!this.isShowMessageAckOnly) {
          this.parseReads(resp.reads);
          this.parseDeliveries(resp.deliveries, resp.reads);
          this.parseUnreads(resp.reads);
          this.parseFallbackStatus(resp.fallback_status);
        }
      },
      err => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  parseReads(reads: MessageReadAPI[]) {
    // filter not myself
    let notMyself = _.filter(reads, (r) => {
      // this.parseImportantUser(r.account)

      return r.user_id !== this.message.sent_by;
    });

    this.reads = _.orderBy(notMyself, ['timestamp'], ['desc']);
  }

  parseMyAcknowledgement() {
    let messageAcks = this._infoMessageService.getUnqiueEarliestMessageAcksByMessageId(this.message.message_id);
    // console.log(messageAcks);

    let myAcks = _.filter(messageAcks, (a) => {
      return a.sent_by === this._accountManagerService.userId;
    });

    let parsedAcks = _.map(myAcks, (ack) => {
      return {
        timestamp: ack.timestamp,
        account: ack.user,
        isAckedByMe: ack.sent_by === this._accountManagerService.userId
      }
    })
    
    this.acks = _.orderBy(parsedAcks, ['timestamp'], ['desc']);
    // console.log(this.acks);
  }

  parseAcknowledgements(acks: MessageAckAPI[]) {
    let notMyAcks = _.filter(acks, (a) => {
      return a.user_id !== this.message.sent_by;
    });
    
    let parsedAcks = _.map(notMyAcks, (ack) => {
      let contact = this._userContactService.getUserContactByUserId(ack.user_id)
      // this.parseImportantUser(contact)

      return {
        ...ack,
        account: contact,
        isAckedByMe: ack.user_id === this._accountManagerService.userId
      }
    })
    
    this.acks = _.orderBy(parsedAcks, ['timestamp'], ['desc']);
  }

  parseDeliveries(deliveries: MessageDeliveryAPI[], reads: MessageReadAPI[]) {
    // get unique earliest delivery of user
    let groupByUser = _.groupBy(deliveries, 'user_id');

    let readUserIds = _.map(this.reads, 'user_id')
    // console.log('groupByUser', groupByUser);
    let unique = _.map(groupByUser, (datas, userId) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];

      // if (_.includes(readUserIds, userId)) {
      //   let read = _.find(this.reads, ['user_id', userId])
      //   let remainDelivers = _.filter(sorted, (d) => d.timestamp <= read.timestamp)
      //   data = _.last(remainDelivers)
      // }

      return data;
    });
    // filter not myself
    let notMyself = _.filter(unique, (d) => {
      // this.parseImportantUser(d.account)

      return d.user_id !== this.message.sent_by;
    });

    // let allReadIds = _.map(reads, 'user_id')
    // let uniqDelivery = _.filter(notMyself, (d) => {
    //   return allReadIds.indexOf(d.user_id) === -1
    // });

    // this.deliveries = _.orderBy(uniqDelivery, ['timestamp'], ['desc']);

    this.deliveries = _.orderBy(notMyself, ['timestamp']);
    // console.log('this.deliveries', this.deliveries);
  }

  parseUnreads(reads: MessageReadAPI[]) {
    let notMyselfRead = _.filter(reads, (r) => {
      return r.user_id !== this.message.sent_by;
    });
    let allReadIds = _.map(notMyselfRead, 'user_id')

    let allRecipients = [];
    if (this.message.whisperContact) {
      allRecipients.push(this.message.whisperContact.user_id)
    } else {
      allRecipients = _.filter(this.chat.members, (r) => {
        return r !== this.message.sent_by;
      });
    }
    let unreadIds = _.difference(allRecipients, allReadIds)

    this.unreads = _.map(unreadIds, (uid) => {
      let contact = this._userContactService.getUserContactByUserId(uid)
      // this.parseImportantUser(contact)

      return contact
    })
  }

  parseFallbackStatus(fallbackStatus: any[]): void {
    fallbackStatus.sort((a, b) => {
      return a.account.name.toLowerCase().localeCompare(b.account.name.toLowerCase());
    });
    _.each(fallbackStatus, (f) => {
      let statusText;
      switch (f.status) {
        case 0:
          statusText = 'WEBCLIENT.CHATROOM.FALLBACK_STATUS.PENDING';
          break;
        case 1:
          statusText = 'WEBCLIENT.CHATROOM.FALLBACK_STATUS.SENT';
          break;
        case -1:
          statusText = 'WEBCLIENT.CHATROOM.FALLBACK_STATUS.ERROR';
          break;
      }
      if (statusText) {
        f.statusText = statusText
      }
    })

    // _.forEach(fallbackStatus, (f) => {
    //   if (f.account) {
    //     f.account.importantLevel = this._userContactService.checkIfUserIsImportant(f.user_id || f.account.user_id)
    //   }
    // })
    
    this.fallbackStatus = fallbackStatus;
  }

  // parse important user for display
  parseImportantUser(contact: UserContact): void {
    if (contact) {
      contact.importantLevel = this._userContactService.checkIfUserIsImportant(contact.user_id)
    }
  }

}
