export class UserContact {
  acc_type: any;
  account_token?: string;
  company: string;
  company_domain?: string;
  censors?: string[];
  create_date?: string;
  deleted?: any;
  department: string;
  department_name?: string;
  department_code: string;
  device_count: number;
  email: string;
  job_code: string;
  job_title?: string;
  hashed_password?: string;
  locked?: string;
  mobile: string;
  name: string;
  pic: string;
  public_message: string;
  registration_date?: string;
  registration_state?: string;
  skype_id: string;
  staff_name?: string;
  staff_id: string;
  store_code: string;
  store_name?: string;
  t: number;
  timestamp: number;
  title?: string;
  uid: string;
  update_date: string;
  user_id: string;
  user_name?: string;
  out_of_office: UserOutOfOfficeRange;
  user_state: UserContactUserState;

  // Local Controls
  is_online?: boolean;
  last_seen?: number;

  isSelected?: boolean;

  // Display
  avatarImageSrc?: string;
  
  // Important User
  importantLevel?: number;
}

export class UserContactUserState {
  user_state: number;
  expired_at: number;
}

export class UserOutOfOfficeRange {
  start_at: string;
  expired_at: string;
}