import { Injectable } from '@angular/core';
import { TeamNoteNotification, NotificationType } from '../models/notification';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { CronjobTracker, CronJob, CronJobStatus, CronJobType } from '../../cronjob-tracker/models/cronjob';

@Injectable()
export class NotificationCenterService {

  notifications: TeamNoteNotification[] = [];
  notifications$: BehaviorSubject<TeamNoteNotification[]> = new BehaviorSubject<TeamNoteNotification[]>([]);

  centerOpenState: boolean = true;
  centerOpenState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  criticalMessageNotification$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  openNotificationCenter(): void {
    this.centerOpenState = true;
  }

  readCriticalMessageNotification(message_id: string): void {
    this.criticalMessageNotification$.next(message_id);
  }

  closeNotificationCenter(): void {
    this.centerOpenState = false;
    this.centerOpenState$.next(false);
  }

  sortNotifications(): void {
    this.notifications = _.orderBy(this.notifications, ['updateTime'], ['desc']);
  }

  clearAllNotifications(): void {
    this.notifications = [];
    this.updateNotificationSubject();
  }

  updateNotificationSubject(): void {
    this.sortNotifications();
    this.notifications$.next(this.notifications);
  }

  addChatMessageNotification(): void {

  }

  addNewsNotification(): void {

  }

  addCronjobNotification(): void {

  }

  updateCronjobNotification(jobTracker: CronjobTracker, onClick: Function): void {
    let original = _.find(this.notifications, {id: jobTracker.jobId});
    if (!original) {
      if (jobTracker.jobType === CronJobType.CRITICAL_MESSAGE_ALERT) {
        this.notifications.push(
          new TeamNoteNotification(
            jobTracker.jobId, 
            NotificationType.CHAT,
            jobTracker,
            null,
            jobTracker.jobInfo.alertTimestamp
          )
        );
      } else {
        this.notifications.push(
          new TeamNoteNotification(
            jobTracker.jobId, 
            NotificationType.CRONJOB,
            jobTracker,
            onClick,
            jobTracker.job.update_date
          )
        );
      }

      // this.notifications.push(
      //   new TeamNoteNotification(
      //     jobTracker.jobId, 
      //     NotificationType.CRONJOB,
      //     jobTracker,
      //     onClick,
      //     jobTracker.job.update_date
      //   )
      // );
    } else {
      original.body = jobTracker;
      original.updateTime = jobTracker.job.update_date;
    }
    this.updateNotificationSubject();
  }


}
