import { Injectable } from "@angular/core";

import * as _ from 'lodash';
import { TeamnoteConfigService } from "../../configs/teamnote-config.service";
import { AttachmentTypeConstant } from "../../constants/attachment-type.constant";
import { FileManagerService } from "../file-manager/file-manager.service";
import { WebclientService } from "../../webclient/webclient.service";

@Injectable()
export class InputValidationService {
  // REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

  REGEX_ASCII = /[^ -~\n]+/g;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _fileManagerService: FileManagerService,
    private _webclientService: WebclientService
  ) {}

  checkIsNotEmpty(input: any): boolean {
    if (!input || input == "") {
      return false;
    }
    return true;
  }

  checkLoginPasswordPolicy(password: string): boolean {
    // Check length first
    if (password.length < this._teamnoteConfigService.config.GENERAL.PASSWORD_POLICY_LENGTH) {
      return false;
    }

    // Check pattern
    let patternRegEx = this._teamnoteConfigService.config.GENERAL.PASSWORD_POLICY_REGEX;
    if (!patternRegEx || patternRegEx.length == 0) {
      // No policy
      return true;
    }

    let regex = new RegExp(patternRegEx);
    return regex.test(password);
  }

  // Files
  isFileValidForChatRoom(file: File): number {
    return this.checkIsFileValidByType(file, _.union(AttachmentTypeConstant.IMAGE_TYPES, AttachmentTypeConstant.VIDEO_TYPES, AttachmentTypeConstant.DOC_TYPES,
    AttachmentTypeConstant.PDF_TYPES), this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALLOW_ALL_FILE_TYPE);
  }
  isFileImage(file: File): number {
    return this.checkIsFileValidByType(file, AttachmentTypeConstant.IMAGE_TYPES);
  }
  isFileVideo(file: File): number {
    return this.checkIsFileValidByType(file, AttachmentTypeConstant.VIDEO_TYPES);
  }
  isFileImageOrVideo(file: File): number {
    return this.checkIsFileValidByType(file, _.union(AttachmentTypeConstant.IMAGE_TYPES, AttachmentTypeConstant.VIDEO_TYPES));
  }
  isFileDoc(file: File): number {
    return this.checkIsFileValidByType(file, _.union(AttachmentTypeConstant.DOC_TYPES,
    AttachmentTypeConstant.PDF_TYPES));
  }
  isFileSheet(file: File): number {
    return this.checkIsFileValidByType(file, AttachmentTypeConstant.SHEET_TYPE);
  }
  checkIsFileValidByType(file: File, types: string[], isBypassTypeChecking?: boolean): number {
    let split = file.name.split('.');
    let extension = _.last(split).toLowerCase();

    // Check Type
    if (!isBypassTypeChecking) {
      if (_.indexOf(types, extension) === -1) {
        // Invalid type
        // this._tnNotificationService.showCustomWarningByTranslateKey('');
        return AttachmentTypeConstant.IS_VALID.INVALID_TYPE;
      }
    }

    // Check Size
    if (this._fileManagerService.getAttachmentType(file.name) != AttachmentTypeConstant.VIDEO) {
      // Check file size if it is not video.
      // if (file.size / 1024 / 1024 >= this._teamnoteConfigService.config.GENERAL.ATTACHMENT_SIZE_LIMIT) {
      if (file.size / 1024 / 1024 >= this._webclientService.FILE_SIZE_LIMIT) {
        // 
        return AttachmentTypeConstant.IS_VALID.INVALID_SIZE;
      }
    } else {
      // Check file size with video size limit if it exists
      if (this._teamnoteConfigService.config.GENERAL.VIDEO_SIZE_LIMIT) {
        if (file.size / 1024 / 1024 >= this._teamnoteConfigService.config.GENERAL.VIDEO_SIZE_LIMIT) {
          // 
          return AttachmentTypeConstant.IS_VALID.INVALID_SIZE;
        }
      }
    }

    return AttachmentTypeConstant.IS_VALID.VALID;
  }

  checkIfTextHasChineseChar(text: string): boolean {
    let result = this.REGEX_ASCII.test(text);
    this.REGEX_ASCII.lastIndex = 0;
    return result;
    // return this.REGEX_CHINESE.test(text);
  }

}
