/**
 * BOC Configs
 *
 * Created by Shan - 2018-05-28
 */

import { TeamNotePasswordPolicyConstant } from '../../constants/password-policy.constant';
import { TeamNoteConfig } from '../../configs/models/config';
import union from 'lodash/union';
import cloneDeep from 'lodash/cloneDeep';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';

export const BocConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: 'boc'
  },
  GENERAL: {
    PAGE_TITLE: 'BOCHK EIM',
    PASSWORD_POLICY_REGEX: TeamNotePasswordPolicyConstant.BOC,
    IS_RESTRICTED_ATTACHMENT: true,
    ATTACHMENT_SIZE_LIMIT: 10,
    VIDEO_SIZE_LIMIT: 10
  },
  IMAGES: {
    NOTIFICATION_ICON: 'assets/images/clients/boc/notification_icon.png',
    FAVICON: 'assets/images/clients/boc/favicon.png',
    FAVICON_ALERT: 'assets/images/clients/boc/favicon_alert.png'
  },
  LOGGER: {
    ENABLE_LOGGER: false
  },
  WEBCLIENT: {
    GENERAL: {
      IS_ALLOW_EXTEND_WS_CONNECTION_THRESHOLD: true
    },
    CORPORATE_MATERIAL: {
      IS_GRID_VIEW: true,
      IS_ALLOW_CHANGE_VIEW_MODE: false
    },
    CONTACT: {
      HIDE_GROUP_AVATAR: true,
    },
    CHATROOM: {
      VALID_FILE_TYPES: JSON.stringify(union(
        AttachmentTypeConstant.IMAGE_TYPES,
        AttachmentTypeConstant.PDF_TYPES,
        AttachmentTypeConstant.VIDEO_TYPES
      )),
      VALID_FILE_TYPES_DISPLAY: JSON.stringify(union(
        AttachmentTypeConstant.IMAGE_TYPES_DISPLAY,
        AttachmentTypeConstant.PDF_TYPES_DISPLAY,
        AttachmentTypeConstant.VIDEO_TYPES_DISPLAY
      )),
      IS_ALWAYS_ALLOW_DOWNLOAD: true,
      IS_ENABLE_MESSAGE_ACK: true,
      IS_SHOW_ACK_INFO: true,
      IS_ENABLE_MENTION: true
    },
    WATERMARK: {
      DEFAULT_WATERMARK: {'update_date': '1570172980.1135', 'pic_position': 3,
      'pic': '', 'create_date': '1470474524.6409', 'staff_id_spacing': '28.0000',
      'staff_id_degree': '30.0000', 'staff_id_format': '[user_name] [timestamp]',
      'company_domain': 'boc', 'mode': 1, 'show_pic': 0, 'staff_id_color': '26E85454', 'show_staff_id': 1}
    }
  }
};
