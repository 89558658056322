import { Injectable } from '@angular/core';

import { Title } from '@angular/platform-browser';

import * as _ from 'lodash';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { ChatMessageService } from '../../webclient/services/data/messages/chat-message/chat-message.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';

@Injectable()
export class WebsiteTitleService {

  baseTitle: string = "";

  isPlayNotificationAudio: boolean = false;
  isPlayingAudio: boolean = false;
  notificationAudioObj: HTMLAudioElement;

  notificationAudioImportantObj: HTMLAudioElement;

  constructor(
    private titleService: Title,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _chatMessageService: ChatMessageService,
    private _tnNotificationService: TnNotificationService
  ) { 
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.baseTitle = config.GENERAL.PAGE_TITLE;
      this.isPlayNotificationAudio = config.NOTIFICATION.PLAY_NOTIFICATION_AUDIO;
    });

    this.notificationAudioObj = new Audio();
    this.notificationAudioObj.setAttribute('src', this._teamnoteConfigService.config.NOTIFICATION.NOTIFICATION_AUDIO_NORMAL)
    this.notificationAudioObj.loop = true;
    this.notificationAudioObj.load();

    this.notificationAudioImportantObj = new Audio();
    this.notificationAudioImportantObj.setAttribute('src', this._teamnoteConfigService.config.NOTIFICATION.NOTIFICATION_AUDIO_IMPORTANT)
    this.notificationAudioImportantObj.loop = true;
    this.notificationAudioImportantObj.load();
  }

  updateFavIcon(isShowAlert: boolean): void {
    let link: any = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    if (isShowAlert) {
      link.href = this._teamnoteConfigService.config.IMAGES.FAVICON_ALERT;
    } else {
      link.href = this._teamnoteConfigService.config.IMAGES.FAVICON;      
    }
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  playNotificationSound(): void {
    this.stopNotificationSound();
    if (!this.isPlayNotificationAudio || this.isPlayingAudio) {
      return;
    }
    this.isPlayingAudio = true;

    // get highest level
    let highestLevel = this._chatMessageService.getHighestImportantLevelOfMessages(_.toArray(this._chatMessageService.unreadMessages));
    
    switch (highestLevel) {
      case 1:
        let importantAudioPlayPromise = this.notificationAudioImportantObj.play();

        // if (importantAudioPlayPromise !== undefined) {
        //   importantAudioPlayPromise.then((resp) => {
        //     // Autoplay started!
        //     // console.log('Autoplay started!');
        //   }).catch(error => {
        //     // Autoplay was prevented.
        //     // console.log('Autoplay failed!', error);
        //     if (!this._tnNotificationService.alertRef) {
        //       this._tnNotificationService.showAlert(
        //         null,
        //         "GENERAL.NO_INTERACTION_ALERT",
        //         null,
        //         "GENERAL.CONFIRM",
        //         () => {
        //           this.notificationAudioImportantObj.play()
        //         }
        //       );
        //     }
        //   });
        // }
        break;
      default:
        let playPromise = this.notificationAudioObj.play();

        // if (playPromise !== undefined) {
        //   playPromise.then((resp) => {
        //     // Autoplay started!
        //     // console.log('Autoplay started!');
        //   }).catch(error => {
        //     // Autoplay was prevented.
        //     // console.log('Autoplay failed!', error);
        //     if (!this._tnNotificationService.alertRef) {
        //       this._tnNotificationService.showAlert(
        //         null,
        //         "GENERAL.NO_INTERACTION_ALERT",
        //         null,
        //         "GENERAL.CONFIRM",
        //         () => {
        //           this.notificationAudioObj.play();
        //         }
        //       );
        //     }
        //   });
        // }
    }
  }

  stopNotificationSound(): void {
    this.notificationAudioObj.pause();
    this.notificationAudioImportantObj.pause();
    this.isPlayingAudio = false;
  }

  setWebsiteTitle(count: number): void {
    let countDisplay = '';

    if (count > 0) {
      countDisplay = '(' + count + ') ';
      this.playNotificationSound();
      // @ts-ignore
      window.tnElectronApi ? window.tnElectronApi.updateBadge(count) : null;
    } else {
      this.stopNotificationSound();
      // @ts-ignore
      window.tnElectronApi ? window.tnElectronApi.updateBadge(null) : null;
    }

    let title = countDisplay + this.baseTitle;

    if (this._teamnoteConfigService.config.GENERAL.TITLE_LABEL) {
      title = this._teamnoteConfigService.config.GENERAL.TITLE_LABEL + title;
    }

    this.titleService.setTitle(title);
    this.updateFavIcon(count > 0);
  }

}
