import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NotificationCenterService } from './services/notification-center.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TeamNoteNotification } from './models/notification';
import { CronJobType } from '../cronjob-tracker/models/cronjob';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'tn-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {

  @ViewChild('notificationCenterDropdown', {static: false}) notificationCenterDropdown: BsDropdownDirective;

  lastOpenTime: number = 0;
  unreadCounts: number = 0;

  notifications: TeamNoteNotification[] = [];
  readCriticalMsgId: string[] = [];

  private notiSub: Subscription;
  private cmNotiSub: Subscription;

  constructor(
    private _notificationCenterService: NotificationCenterService
  ) { 
    this._notificationCenterService.centerOpenState$.subscribe(
      state => {
        if (!state) {
          this.closeCenter();
        }
      }
    );
    this.notiSub = this._notificationCenterService.notifications$.subscribe(
      notifications => {
        this.handleNotificationUpdate(notifications);
      }
    );
    this.cmNotiSub = this._notificationCenterService.criticalMessageNotification$.subscribe(
      messageId => {
        if (messageId) {
          this.updateUnreadCriticalMsgCount(messageId);
        }
      }
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.notiSub.unsubscribe();
    this.cmNotiSub.unsubscribe();
  }

  handleNotificationUpdate(notifications: TeamNoteNotification[]): void {
    this.notifications = notifications;
    this.updateUnreadCount();
  }

  updateUnreadCount(): void {
    // Get unread count
    let unreads = _.filter(this.notifications, (n) => {
      return parseFloat(n.updateTime) > this.lastOpenTime && !_.includes(this.readCriticalMsgId, n.id)
    });
    this.unreadCounts = unreads.length;
  }

  updateUnreadCriticalMsgCount(messageId: string): void {
    if (this.unreadCounts === 0) {
      return;
    }

    let targetCriticalMsgAlert = _.find(this.notifications, { id: messageId })

    if (targetCriticalMsgAlert) {
      this.readCriticalMsgId.push(messageId);
      this.unreadCounts -= 1;
    }
  }

  onOpenChange(isOpen: boolean): void {
    if (isOpen) {
      this._notificationCenterService.openNotificationCenter();
      this.updateLastOpenTime();
      this.updateUnreadCount();
    }
  }

  updateLastOpenTime(): void {
    this.lastOpenTime = new Date().getTime() / 1000;
  }

  closeCenter(): void {
    this.updateLastOpenTime();
    this.notificationCenterDropdown.hide();
  }

  tryToClose(): void {
    this._notificationCenterService.closeNotificationCenter();
  }

}
