import { Message} from './../../../../models/message';
import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Chat } from '../../../../models/chat';

import { ChatRoomService } from '../chat-room.service';

import * as _ from 'lodash';
import { AccountManagerService } from '../../../services/account/account-manager.service';
import { ContextMenuService, ContextMenuItem, EmojiMenuItem } from '../../../../utilities/context-menu/context-menu.service';
import { LocalStorageManagerService } from '../../../../utilities/local-storage/local-storage-manager.service';
import { ModuleManagerService } from '../../../services/module/module-manager.service';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TnDialogService } from '../../../../utilities/tn-dialog/tn-dialog.service';
import { MessageInfoComponent } from '../message-info/message-info.component';
import { MessageEmojiInfoComponent } from '../message-emoji-info/message-emoji-info.component';
import { EmojiMenuComponent } from '../../../../utilities/emoji/emoji-menu/emoji-menu.component';
import { UserContact } from '../../../../models/user-contact';
import { ContactPickerService } from '../../../contact-picker/contact-picker.service';
import { LoggerService } from '../../../../utilities/logger/logger.service';
import { CHAT_ROOM_MODE } from '../chat-room.component';
import { MessageTypeConstant } from '../../../../constants/message-type.constant';
import { UserConstant } from '../../../../constants/user.constant';
import { PresenceTypeConstant } from '../../../../constants/presence-type.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../../../constants/local-storage-key.constant';
import { ModuleKeyDefinition } from '../../../../constants/module.constant';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';
import { ChatMessageService } from '../../../services/data/messages/chat-message/chat-message.service';
import { WebclientService } from '../../../webclient.service';
import { SocketService } from '../../../../webclient/services/socket/socket.service';
import { EmojiService } from '../../../../utilities/emoji/emoji.service';
import { MessagesService } from '../../../services/data/messages/messages.service';
import { TranslateManagerService } from '../../../../utilities/translate/translate-manager.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../../../utilities/service/utilities.service';
import { CronJobTaskInitialResponse, CronJobType, CronjobExportCriticalMessageDetail } from '../../../../utilities/cronjob-tracker/models/cronjob';
import { CronjobTrackerService } from '../../../../utilities/cronjob-tracker/cronjob-tracker.service';
import { NotificationCenterService } from '../../../../utilities/notification-center/services/notification-center.service';
import { IdleChatroomService } from '../../../../utilities/idle-chatroom/idle-chatroom.service';

var format = require('date-fns/format');

@Component({
  selector: 'tn-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input() messages: Message[];
  @Input() chat: Chat;
  @Input() scrollToBottom: boolean;
  @Input() isLoadingHistory: boolean;
  @Input() isUnlockedEncryptedMessage: boolean;
  @Input() isAckToRead: boolean;
  @Input() isEnableMsgAck: boolean;
  @Input() isEnableStarMessage: boolean;
  @Input() isEnableMessageDelete: boolean;
  @Input() isEnableImportantUsers: boolean;
  @Input() isDisableEncrypyedMsgDownload: boolean;
  @Input() isEnableExportCriticalMessage: boolean;
  @Input() selectedMessageIds: string[];
  @Input() chatRoomMode: number;
  @Input() lastMessagePointer: Message;
  @Input() editingMessageId: string;

  @ViewChild('messagesWrapper', {static: false}) messagesWrapper: ElementRef;
  @ViewChild('messageRow', {static: false}) messageRow: ElementRef;

  @Output() onMessageClick = new EventEmitter<Message>();
  @Output() onUserAvatarClick = new EventEmitter<UserContact>();
  @Output() toggleReply = new EventEmitter<Message>();
  @Output() toggleEdit = new EventEmitter<Message>();
  @Output() onEncryptedMsgUnlock = new EventEmitter<Message>();
  @Output() updateSelectedMessages = new EventEmitter<string[]>();
  @Output() updateChatRoomMode = new EventEmitter<number>();
  @Output() onMessageParentClick = new EventEmitter<{msg: Message, prevMsg: Message}>();
  @Output() onChatSearchByKeyword = new EventEmitter<string>();
  @Output() triggerBottomRightMenuShouldDisplay = new EventEmitter<string>();

  parsedMessages: Message[];
  MSG_TYPE = MessageTypeConstant
  CHAT_ROOM_MODE = CHAT_ROOM_MODE;

  isShowMessageID: boolean = false;
  isEnableReadTicks: boolean = false;
  isEnableMsgEmoji: boolean = false;

  contextMenus: ContextMenuItem[] = [];
  emojiMenus: EmojiMenuItem = null;

  heightTracker: number = 0;

  showUserField: boolean;
  userField: any;
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  private currI18nKeySub: Subscription;
  currI18nKey: string = "en";

  private pageVisibleSub: Subscription;
  isEdleRendering: boolean = false;
  private idleSubscriber: Subscription = null;

  constructor(
    private _chatRoomService: ChatRoomService,
    private _accountManagerService: AccountManagerService,
    private _contextMenuService: ContextMenuService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _dialog: MatDialog,
    private _tnDialogService: TnDialogService,
    private _contactPickerService: ContactPickerService,
    private _loggerService: LoggerService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _chatMessageService: ChatMessageService,
    private _webClientService: WebclientService,
    private _emojiService: EmojiService,
    private _messageService: MessagesService,
    private _translateManagerService: TranslateManagerService,
    private _socketService: SocketService,
    private _utilitiesService: UtilitiesService,
    private _cronjobTrackerService: CronjobTrackerService,
    private _notificationCenterService: NotificationCenterService,

    private _webclientService: WebclientService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _idleChatroomService: IdleChatroomService,
    
  ) {
    this.currI18nKeySub = this._translateManagerService.currentTranslation$.subscribe(lang => {
      let currLang = this._translateManagerService.getCurrentLangI18nKey()
      switch (currLang) {
        case 'en':
          this.currI18nKey = 'en'
          break;
        case 'zh-Hant':
          this.currI18nKey = 'zh-Hant'
          break;
        case 'zh-Hans':
          this.currI18nKey = 'zh-Hans'
          break;
      }
    });

    this.pageVisibleSub = this._webclientService.isPageVisible$.subscribe(visible => {
      // this._loggerService.debug("Is Page Visible?: [[[ {visibility} ]]]".replace('{visibility}', visible ? 'Yes' : 'No'));
      // console.log('[messages.components] isPageVisible', visible);

      if (visible) {
        // console.log('[messages.components] Change Detector just reattached ---> [ restart to detect rendering ]\n------------------------------------------------------------------------')
        // this._loggerService.debug('Change Detector just reattached --- restart to detect rendering')
        this._changeDetectorRef.reattach()
      } else {
        // console.log('[messages.components] Change Detector just detached ---> [ stop to detect rendering ]\n------------------------------------------------------------------------')
        // this._loggerService.debug('Change Detector just detached --- stop to detect rendering')
        this._changeDetectorRef.detach()
      }
    });
  }

  messageTrackBy(index, item) {
    // return item.message_id;
    return `${item.message_id}${item.timestamp}`;
  }

  ngOnInit() {
    this.isEnableReadTicks = this._webClientService.checkIfEnableMessageReadTicks();
    this.isShowMessageID = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_SHOW_MESSAGE_ID; 
    this.isEnableMsgEmoji = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)  
    this.getUserField();

    // this._idleChatroomService.reset();
    // this._idleChatroomService.initListener();

    // this.idleSubscriber = this._idleChatroomService.userInactive$.subscribe((isIdle) => {
      // if (isIdle != this.isEdleRendering) {
        // console.log('isIdle', isIdle);
        // this.isEdleRendering = isIdle
      
        // if (isIdle) {
          // console.log('detached');
          // this._changeDetectorRef.detach()
          // this.triggerBottomRightMenuShouldDisplay.emit('showScrollToBottom');
        // } else {
          // console.log('reattach');
          // this._changeDetectorRef.reattach()
          // this.triggerBottomRightMenuShouldDisplay.emit('onQuickTravelClick');
        // }
      // }
    // });
  }

  ngOnChanges() {
    // if (this.isEdleRendering) {
      // this.throttleUpdateMessages();
    // } else {
      // this.updateParsedMessage()
    // }

    this.throttleUpdateMessages();
    // this.updateParsedMessage();
    // console.log('this.parsedMessages', `[${this.parsedMessages.length}]`);
    // this.parsedMessages = this._chatRoomService.getParsedMessages(this.messages, this.lastMessagePointer);
    // this.scrollToMessageBottom();
  }

  private THROTTLE_THRESHOLD = 250;
  private throttleUpdateMessages = _.throttle(this.updateParsedMessage, this.THROTTLE_THRESHOLD);
  updateParsedMessage() {
    this.parsedMessages = this._chatRoomService.getParsedMessages(this.messages, this.lastMessagePointer);
    this.scrollToMessageBottom();
  }

  ngAfterViewChecked() {
    this.scrollToMessageBottom();
  }

  ngOnDestroy() {
    this.currI18nKeySub.unsubscribe();
    this.pageVisibleSub.unsubscribe();

    if (this.idleSubscriber) {
      this.idleSubscriber.unsubscribe();
      // this._idleChatroomService.clearListener();
    }
  }

  get totalChatMembers() {
    return this.chat.members.length - 1; // except me
  }

  setSelectStatusOfMessage() {
    _.each(this.parsedMessages, (m) => {
      m.isSelected = _.indexOf(this.selectedMessageIds, m.message_id) != -1;
    });
  }

  scrollToMessageBottom(): void {
    if (this.scrollToBottom) {
      try {
        let newHeight = this.messagesWrapper.nativeElement.clientHeight;
        
        if (newHeight == this.heightTracker) {
          return;
        }
        // console.warn('scrolling to bottom...');
        this.messagesWrapper.nativeElement.scrollIntoView(false);
        this.heightTracker = newHeight;
      } catch(err) { }
    }
  }

  onEmojiDetailClick(event: MouseEvent, m: Message, emojiStr: string, emojiIdx: number): void {
    event.stopPropagation();
    // if (emojiIdx === 2) {
    if (emojiIdx === 2 || m.isSentByMe) {
      // if click on emoji count bubble on message, then go to emoji detail
      this.onMessageEmojiInfo(m);
    } else {
      // if clicking on emoji on message sent by others, then send/unsend/swap the target emoji 
      if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)) {
        this._emojiService.addToRecent(emojiStr);
        this._messageService.onEmojiClick(m, m.isSentEmoji, emojiStr);
      }
    }
  }

  onClick(event: MouseEvent, message: Message, isRealOnClick: boolean): void {
    // Prevent parent div click event
    event.stopPropagation();

    if (!isRealOnClick) {
      this.onContextMenu(event, message);
      return;
    }

    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Clicked on message when chat room is not normal mode, passing...");
      return;
    }

    if (!this.isUnlockedEncryptedMessage && message.parsedBody.is_encrypted) {
      this.onEncryptedMsgUnlock.emit(message);
    } else {
      this.onMessageClick.emit(message);
    }
  }

  getEmojiMenu(m: Message): void {
    let emojiClickCallback = (emoji) => {
      this._emojiService.addToRecent(emoji);
      // let unicodeEmoji = this._emojiService.getUnicodeByEmoji(emoji);
      this._messageService.onEmojiClick(m, m.isSentEmoji, emoji);
    }

    let emojiSendCallback = () => this.onSendEmoji(m)

    // this.emojiMenus = this._emojiService.buildEmojiMenuInDropDownMenu(m, emojiClickCallback, emojiSendCallback)
    
    this.emojiMenus = {
      ...this._emojiService.buildEmojiMenuInDropDownMenu(m),
      emojiClickCallback: emojiClickCallback,
      emojiSendCallback: emojiSendCallback
    }
  }

  getContextMenu(m: Message): void {
    this.contextMenus = [];

    // Text message copy
    if (!this._webClientService.disable_in_app_copy) {
      if (m.type == MessageTypeConstant.TEXT) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.COPY',
          action: () => this.onTextMsgCopy(m)
        });
      }
    }

    //message emoji info
    // check permission for sending message emoji
    if (this.checkIfNeedShowEmojiDetail(m)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.EMOJI_INFO.TITLE',
        action: () => this.onMessageEmojiInfo(m)
      });
    }

    // message info
    if (m.isSentByMe || 
      (this.chat.t === PresenceTypeConstant.GROUP_BROADCAST && this.chat.admins.indexOf(this._accountManagerService.userId) !== -1)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.INFO',
        action: () => this.onMessageInfo(m)
      });
    }
    if (this.isEnableMsgAck) {
      if (!m.isSentByMe && m.parsedBody.acknowledgement && m.parsedBody.acknowledgement.mode !== 'disabled') {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.ACKNOWLEDGEMENT_INFO',
          action: () => this.onAcknowledgementInfo(m)
        });
      }
    }
    // reply
    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_REPLY)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.REPLY',
        action: () => this.onReply(m)
      });
    }
    // forward
    if (!this._webClientService.disable_in_app_forward) {
      if (this.isUnlockedEncryptedMessage || !m.parsedBody.is_encrypted) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.SELECT',
          action: () => this.onForward(m)
        });
      }
    }
    // edit
    // if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EDIT)) {
    if (m.isSentByMe && (m.type == MessageTypeConstant.TEXT || (m.type == MessageTypeConstant.ATTACHMENT && !_.isEmpty(m.parsedBody.message)))) {
      let recallPeriod = parseInt(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_EDIT_PERIOD));
      if (recallPeriod == -1 || (parseFloat(m.timestamp) + recallPeriod) > _.now() / 1000) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.EDIT',
          action: () => this.onEdit(m)
        });
      }   
    }

    // Star message
    // if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_STAR)) {
    if (this.isEnableStarMessage && m.message_id) {
      let config = {
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.STAR',
        action: () => this.onStar(m)
      }

      if (m.isStarredByMe) {
        config = {
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.UNSTAR',
          action: () => this.onUnStar(m)
        }
      }

      this.contextMenus.push(config);
    }
    // }

    // recall
    if (m.isSentByMe) {
      let recallPeriod = parseInt(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD));
      if (recallPeriod == -1 || (parseFloat(m.timestamp) + recallPeriod) > _.now() / 1000) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.RECALL',
          action: () => this.onRecall(m)
        });
      }      
    }
    // report
    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_REPORT)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.REPORT',
        action: () => this.onReport(m)
      });
    }
    // delete
    if (this.isEnableMessageDelete) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.DELETE',
        action: () => this.onDelete(m)
      });
    }
    // Export 
    if (this.isEnableExportCriticalMessage && m.parsedBody.acknowledgement) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.EXPORT_CRITICAL_MSG',
        action: () => this.onExportCriticalMessage(m)
      });
    }
  }

  onContextMenu(event, m: Message): void {
    event.preventDefault();
    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Right-Clicked on message when chat room is not normal mode, passing...");
      return;
    }

    if (!this.isUnlockedEncryptedMessage && m.parsedBody.is_encrypted) {
      this._loggerService.debug("Right-Clicked on encrypted message, passing...");
      return;
    }

    this._loggerService.log("Open context menu on message: " + m.message_id);

    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)) {
      this.isEnableMsgEmoji = true
      this.getEmojiMenu(m);
    }
    this.getContextMenu(m);

    this._contextMenuService.showContextMenu(
      event.clientX,
      event.clientY,
      this.contextMenus,
      m.message_id,
      this.emojiMenus,
      this.isEnableMsgEmoji,
      this.messageRow ? this.messageRow.nativeElement.clientWidth : null
    )
  }
  onTextMsgCopy(m: Message): void {
    this._loggerService.log("Clicked copy on message: " + m.message_id);
    // this.toggleReply.emit(m);
    this.copyContent(m.parsedBody.message || '')
  }
  onReply(m: Message): void {
    this._loggerService.log("Clicked reply ßon message: " + m.message_id);
    this.toggleReply.emit(m);
  }
  onEdit(m: Message): void {
    this._loggerService.log("Clicked edit on message: " + m.message_id);

    if (m.message_id === this.editingMessageId) {
      return;
    }

    this.toggleEdit.emit(m);
  }
  onForward(m: Message): void {
    this._loggerService.log("Clicked forward on message: " + m.message_id);
    this.updateChatRoomMode.emit(CHAT_ROOM_MODE.FORWARD);
    this.onSelect(m);
  }
  onSendEmoji(m: Message): void {
    this._loggerService.log("Clicked emoji selection menu on message: " + m.message_id);

    let DialogData: any = {
      onEmojiSelectFunction: (emoji) => {
        // let unicodeEmoji = this._emojiService.getUnicodeByEmoji(emoji);
        this._messageService.onEmojiClick(m, m.isSentEmoji, emoji);
      }
    }

    if (m.isSentEmoji) {
      DialogData.sentEmoji = m.isSentEmoji
    }

    let dialogRef = this._tnDialogService.openTnDialog(EmojiMenuComponent, DialogData);
  }

  onMessageEmojiInfo(m: Message): void {
    this._loggerService.log("Clicked emoji detail on message: " + m.message_id);
    let dialogRef = this._tnDialogService.openTnDialog(MessageEmojiInfoComponent, {
      message: m,
      chat: this.chat
    });
  }

  onMessageInfo(m: Message): void {
    this._loggerService.log("Clicked message info on message: " + m.message_id);
    let dialogRef = this._tnDialogService.openTnDialog(MessageInfoComponent, {
      message: m,
      chat: this.chat,
    });
  }
  onAcknowledgementInfo(m: Message): void {
    this._loggerService.log("Clicked Message report status on message: " + m.message_id);
    
    let dialogRef = this._tnDialogService.openTnDialog(MessageInfoComponent, {
      message: m,
      chat: this.chat,
      isShowMessageAckOnly: true
    });
  }
  onRecall(m: Message): void {
    this._loggerService.log("Clicked recall on message: " + m.message_id);
    this._chatRoomService.recallMessage(m.message_id);
  }
  onReport(m: Message): void {
    this._loggerService.log("Clicked report on message: " + m.message_id);
    // TODO: confirm first
    this._chatRoomService.reportMessage(m.message_id);
  }
  onDelete(m: Message): void {
    this._loggerService.log("Clicked delete on message: " + m.message_id);
    this._tnNotificationService.showConfirmByTranslateKey(
      "WEBCLIENT.CHATROOM.DELETE.CONFIRM_MSG",
      () => {
        this._chatRoomService.deleteMessage(this.chat.chat_id, m.message_id);
      }
    );
  }
  onExportCriticalMessage(m: Message): void {
    console.log(m);
    this._loggerService.log("Clicked export on critical message: " + m.message_id);
    // this._tnNotificationService.showConfirmByTranslateKey(
    //   "WEBCLIENT.CHATROOM.DELETE.CONFIRM_MSG",
    //   () => {
    //     this._chatRoomService.deleteMessage(this.chat.chat_id, m.message_id);
    //   }
    // );
    this._chatRoomService.exportChatCriticalMessage(
      this.chat.chat_id, 
      m.message_id,
      (resp: CronJobTaskInitialResponse) => {
        this._cronjobTrackerService.startTrackingCronjob(
          resp.job_id,
          CronJobType.EXPORT_CRITICAL_MESSAGE,
          new CronjobExportCriticalMessageDetail(
            CronJobType.EXPORT_CRITICAL_MESSAGE,
            this.chat.displayName, 
            m.message_id,
            [
              m.message_id,
              'status_export',
              // this.chat.displayName,
            ].join("_"),
            "WEBCLIENT.CHATROOM.CRITICAL_MESSAGE.EXPORT.EXPORT_READY_MSG"
          ),
          null,
          "WEBCLIENT.CHATROOM.CRITICAL_MESSAGE.EXPORT.TITLE"
        );
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHATROOM.CRITICAL_MESSAGE.EXPORT.IN_PROGRESS_MSG");
      },
      err => {

      },)
  }

  onStar(m: Message): void {
    this._loggerService.log("Clicked star on message: " + m.message_id);
    this._chatRoomService.starMessage(this.chat.chat_id, m.message_id);
  }

  onUnStar(m: Message): void {
    this._loggerService.log("Clicked unstar on message: " + m.message_id);
    this._chatRoomService.unstarMessage(this.chat.chat_id, m.message_id);
  }

  // Contact Card
  onUserClick(contact: UserContact): void {
    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Clicked on sender avatar when chat room is not normal mode, passing...");
      return;
    }

    this.onUserAvatarClick.emit(contact);
  }

  // Selection
  onSelect(m: Message): void {
    if (m.isSelected) {
      this.selectedMessageIds = _.without(this.selectedMessageIds, m.message_id);
    } else {
      if (!this.isDisableEncrypyedMsgDownload) {
        this.selectedMessageIds = _.union(this.selectedMessageIds, [m.message_id]);
      } else {
        if (!m.parsedBody.is_encrypted) {
          this.selectedMessageIds = _.union(this.selectedMessageIds, [m.message_id]);
        }
      }
    }
    this.setSelectStatusOfMessage();
    this.updateSelectedMessages.emit(this.selectedMessageIds);
  }

  // parent message
  onParentMessage(msg: Message, prevMsg: Message): void {
    // if (this._chatMessageService.checkIfMessageIsRecalled(msg.message_id)) {
    //   return;
    // }
    if (this._chatMessageService.checkIfMessageIsRecalled(msg)) {
      return;
    }
    this.onMessageParentClick.emit({msg: msg, prevMsg: prevMsg});
  }

  // hashtag
  onHashtagClick(text: string): void {
    this.onChatSearchByKeyword.emit(text);
  }

  // message ID
  getMessageIdDisplay(message: Message): string {
    if (!this.isShowMessageID) {
      return;
    }
    if (!message || !message.message_id) {
      return;
    }
    return `#${format(parseFloat(message.timestamp) * 1000, 'YYYYMMDD-HHmm')}-${message.message_id.substr(0, 4)}`
  }

  // ack
  ackMessage(m: Message): void {
    this._chatRoomService.sendReadForSingleMessage(m.message_id);
  }

  sendMessageAck(m: Message): void {
    if (!this._socketService._isConnected) {
      this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');
      this._loggerService.debug('Web socket is disconnected..., stop to send emoji');
      return;
    }

    if (m.isAcked) {
      return
    }

    this._chatRoomService.sendAckForSingleMessage(m, () => { this._notificationCenterService.readCriticalMessageNotification(m.message_id) });
  }

  getUserField(): void {
    if (Object.keys(this._webClientService.display_user_fields_in_chat).length === 0 || this._webClientService.display_user_fields_in_chat.constructor === Object){
      this.showUserField = false;
    }
    else {
      let role : any;
      this.showUserField = true;
      role = this._webClientService.display_user_fields_in_chat;
      this.userField = role;
    }
  }

  async copyContent(content: string) {
    try {
      this._utilitiesService.copyValueToClipboard(content);
      // if (navigator.clipboard) {
      //   await window.navigator.clipboard.writeText(content)
      // }
    } catch (err) {
      this._tnNotificationService.showSystemError();
    }
  }

  checkIfNeedShowEmojiDetail(message: Message): boolean {
    if (_.isEmpty(message.emojiDisplay)) {
      return false
    }

    return true
  }

  getEmojiPreviewCount(m: Message): number {
    return m.emojis.length - m.emojiDisplay[0].count - m.emojiDisplay[1].count
  }
}
