import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as FileSaver from 'file-saver';
import * as _ from "lodash";
import { TeamnoteConfigService } from "../configs/teamnote-config.service";
import { TeamNoteLocalStorageKeyConstants } from "../constants/local-storage-key.constant";
import { PageUrlConstant } from "../constants/page-url.constant";
import { LocalStorageManagerService } from "../utilities/local-storage/local-storage-manager.service";
import { LoggerService } from "../utilities/logger/logger.service";
import { WebclientLoginService } from "./webclient-login.service";
import { BaseRoutingService } from "../routing/base-routing.service";
import { WebclientLoginResponse } from "./models/webclient-login-response";
import { TnLoaderService } from "../utilities/tn-loader/tn-loader.service";
import { TAndCService } from "../utilities/t-and-c/t-and-c.service";
import { WebclientService } from "../webclient/webclient.service";
import { LanguageConstant } from "../constants/language.constant";
import { TranslateManagerService } from "../utilities/translate/translate-manager.service";
import { Subscription } from 'rxjs';

@Component({
  selector: "tn-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  VERSION_NO: string;
  debugClickCount:number = 0;
  IS_UAT: boolean = false;
  BUILD_LABEL: string;
  SYSTEM_LABEL: string;
  SITE_LABEL: string;
  useNewLoginPageUI: boolean = false;

  LANG_CONSTANT = LanguageConstant;
  langOption: any[] = [];
  excludedLangKeys: string[] = [];
  currLang: string = 'en';

  private currI18nKeySub: Subscription;

  noBaseBackground: boolean;

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _localStorageManagerService: LocalStorageManagerService,
    private _loggerService: LoggerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _baseRoutingService: BaseRoutingService,
    private _tnLoaderService: TnLoaderService,
    private _tAndCService: TAndCService,
    private _webclientService: WebclientService,
    private _translateManagerService: TranslateManagerService,
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.VERSION_NO = config.VERSION.VERSION;
      this.IS_UAT = config.GENERAL.IS_UAT;
      this.BUILD_LABEL = config.GENERAL.BUILD_LABEL;
      this.SYSTEM_LABEL = config.GENERAL.SYSTEM_LABEL;
      this.SITE_LABEL = config.GENERAL.SITE_LABEL;
      
      this.useNewLoginPageUI = config.UI.USE_NEW_LOGIN_PAGE_UI;
      this.excludedLangKeys = this._teamnoteConfigService.config.LANGUAGE.EXCLUSION;
      this.noBaseBackground = config.GENERAL.NO_BASE_BACKGROUND;
    });

    this.currI18nKeySub = this._translateManagerService.currentTranslation$.subscribe(lang => {
      let currLang = this._translateManagerService.getCurrentLangI18nKey()
      this.currLang = currLang;
    });
  }

  currLangDisplay() {
    const lang = _.find(this.langOption, { i18n: this.currLang });

    return lang?.label || ''
  }

  ngOnInit() {
    this.getLanguageOptions();
    this._webclientLoginService.generateDeviceToken();

    if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.FORCE_LOGIN_NEW_TAB) {
      this._loggerService.debug("Enabled force login on new tab, detect refresh/close first");
      this.detectRefreshOrClose();
    } else {
      this._loggerService.debug("Disabled force login on new tab, try landing");
      this.webclientLoginLanding();
    }
  }

  ngOnDestroy() {
    this.currI18nKeySub.unsubscribe();
  }

  getLanguageOptions(): void {
    this.langOption = _.filter(_.toArray(LanguageConstant), (lang) => !_.includes(this.excludedLangKeys, lang.key));
  }

  onLanguageChange(event, lang: string): void {
    let currLang = this._translateManagerService.getCurrentLangI18nKey()
    if (currLang === lang) {
      return;
    }

    this._translateManagerService.changeLang(lang, true); // need store to local storage
  }
  
  detectRefreshOrClose(): void {
    let prevLoginTime = this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.LOGIN_TIME);
    if (prevLoginTime) {
      this._loggerService.debug("It has prev login time, refresh only. Continue landing");
      this.webclientLoginLanding();
    } else {
      this._loggerService.debug("Doesn't have prev login time, new browser Tab, start login flow");
      this.startLoginFlow();
    }
  }

  webclientLoginLanding(): void {
    // Tries to relogin
    let sessionToken = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);

    if (!sessionToken) {
      this._loggerService.debug("No previous session token exist, go to login page");
      this.startLoginFlow();
      return;
    }
    this.reLoginWebClientBySessionToken(sessionToken);
  }

  reLoginWebClientBySessionToken(sessionToken: string): void {
    let reLoginFailCallback = (err?) => {
      this._loggerService.error("Previous session token is expired, redirecting to login page...");
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
      if (err && err.status == 403) {
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
      }
      this.startLoginFlow(); 
    };
    this._webclientLoginService.webclientLoginByToken(
      sessionToken,
      (resp: WebclientLoginResponse) => {
        if (resp.success) {
          this._loggerService.debug("Previous session token is still valid, login successfully");
          this._webclientLoginService.handleWebclientLoginSuccessResponse(resp);
          this.goToWebclientPage();
        } else {
          reLoginFailCallback();
        }
      },
      (err) => {
        reLoginFailCallback(err);
      }
    );
  }

  startLoginFlow(): void {
    this.goToTargetPage(PageUrlConstant.LOGIN.GET_STATE);
    // this.goToTargetPage(PageUrlConstant.LOGIN.LOGIN_METHODS);
  }

  goToTargetPage(pageUrl: string) {
    this.goToTargetPageWithParams(pageUrl);
  }

  goToTargetPageWithParams(pageUrl: string,params?:any) {
    if (pageUrl == PageUrlConstant.WEBCLIENT.BASE) {
      // go to host/webclient
      this.goToWebclientPage();
    } else if (
      _.indexOf(_.values(PageUrlConstant.LOGIN), pageUrl) !== -1
    ) {
      // go to host/login/[pageURL]
      this._router.navigate([pageUrl], { relativeTo: this._route, queryParams:params});
    } else {
      console.log("Page not exists in LOGIN_ACTON", pageUrl);
    }
  }

  goToWebclientPage() {
    this._tAndCService.checkTAndC(
      () => {
        this._tnLoaderService.showSpinner();
        this._baseRoutingService.goToWebclientPage();
      },
      () => {
        this._webclientService.onLogoutWebClient(true);
        this._loggerService.error("Declined updated T&C, redirecting to login page...");
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
        this.startLoginFlow(); 
      }
    );
  }


}
