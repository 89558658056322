export const CronJobStatus = {
  ERROR: -1,
  DISABLED: 0,
  IN_QUEUE: 1,
  RUNNING: 2,
  FINISHED: 9
}

export const CronJobType = {
  EXPORT_CHAT_MESSAGE: "export_chat",
  EXPORT_REPORT_JOB: "export_report_job",
  EXPORT_FORM: "export_form",
  EXPORT_CRITICAL_MESSAGE: "export_critical_message",
  CRITICAL_MESSAGE_ALERT: "critical_message_alert",
}

export class CronjobTracker {
  jobId: string;
  jobType: string;
  jobInfo?: any;

  jobTitle?: string;
  jobTitleTranslateKey?: string;
  isFinished: boolean;
  job: CronJob;
  
  constructor(jobId: string, jobType: string, jobInfo?: any, jobTitle?: string, jobTitleTranslateKey?: string) {
    this.jobId = jobId;
    this.jobType = jobType;
    this.jobInfo = jobInfo;

    this.jobTitle = jobTitle;
    this.jobTitleTranslateKey = jobTitleTranslateKey;

    this.isFinished = false;
    this.job = null;
  }
}

export class CronjobExportChatMessage {
  chatName: string;
  timeIntervalDisplay: string;
  fileName: string;
  finishMsgTranlsteKey: string;

  constructor(chatName: string, timeIntervalDisplay: string, fileName: string, finishMsgTranlsteKey: string) {
    this.chatName = chatName;
    this.timeIntervalDisplay = timeIntervalDisplay;
    this.fileName = fileName;
    this.finishMsgTranlsteKey = finishMsgTranlsteKey;
  }
}
export class CriticalMessage {
  chatName: string;
  alertTimestamp: string;
  alertDisplayDateTime: string;
  alertMsgTranlsteKey: string;

  constructor(chatName: string, alertTimestamp: string, alertDisplayDateTime: string, alertMsgTranlsteKey: string) {
    this.chatName = chatName;
    this.alertTimestamp = alertTimestamp;
    this.alertDisplayDateTime = alertDisplayDateTime;
    this.alertMsgTranlsteKey = alertMsgTranlsteKey;
  }
}
export class CronjobExportCriticalMessageDetail {
  exportType: string;
  chatName: string;
  messageId: string;
  fileName: string;
  finishMsgTranlsteKey: string;

  constructor(exportType: string, chatName: string, messageId: string, fileName: string, finishMsgTranlsteKey: string) {
    this.exportType = exportType;
    this.chatName = chatName;
    this.messageId = messageId;
    this.fileName = fileName;
    this.finishMsgTranlsteKey = finishMsgTranlsteKey;
  }
}

export class CronjobExportReportJob {
  isJob: boolean;
  timeIntervalDisplay: string;

  storeTranslateKey?: string;
  storeName?: string;

  typeTranslateKey?: string;
  typeName?: string;

  finishMsgTranlsteKey: string;
  fileName: string;

  constructor(
    isJob: boolean,
    timeIntervalDisplay: string, 
    storeTranslateKey: string,
    storeName: string,
    typeTranslateKey: string,
    typeName: string,
    fileName: string,
    finishMsgTranlsteKey: string
  ) {
    this.isJob = isJob;
    this.timeIntervalDisplay = timeIntervalDisplay;
    this.storeTranslateKey = storeTranslateKey;
    this.storeName = storeName;
    this.typeTranslateKey = typeTranslateKey;
    this.typeName = typeName;
    this.fileName = fileName;
    this.finishMsgTranlsteKey = finishMsgTranlsteKey;
  }
}

export class CronjobExportForm {
  timeIntervalDisplay: string;

  teamTranslateKey?: string;
  teamName?: string;

  typeTranslateKey?: string;
  typeName?: string;

  finishMsgTranlsteKey: string;
  fileName: string;

  constructor(
    timeIntervalDisplay: string, 
    teamTranslateKey: string,
    teamName: string,
    typeTranslateKey: string,
    typeName: string,
    fileName: string,
    finishMsgTranlsteKey: string
  ) {
    this.timeIntervalDisplay = timeIntervalDisplay;
    this.teamTranslateKey = teamTranslateKey;
    this.teamName = teamName;
    this.typeTranslateKey = typeTranslateKey;
    this.typeName = typeName;
    this.fileName = fileName;
    this.finishMsgTranlsteKey = finishMsgTranlsteKey;
  }
}

export class CronJobTaskInitialResponse {
  job_id: string;
}

export class ExportAttachmenetCronJobResult {
  attachment_id: string;
}

export class CronJob {
  body: string;
  create_date: string;
  cronjob_schedule: string;
  end_time: string;
  job_id: string;
  next_execute_time: string;
  progress_description: string;
  progress_finished: number;
  progress_total: number;
  queue_time: string;
  result: string;
  start_time: string;
  status: number;
  task_name: string;
  update_date: string;
  user_id: string;
}