/**
 * EMSD Strings
 * 
 * Created Date - 2024-08-23
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const EmsdStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      GENERAL: {
        TEAMNOTE: "EMSD Messenger",
        TERMS_AND_CONDITIONS: "Terms and Conditions",

        FILE_TIP: "Maximum size: {{SIZE}}MB",
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "Document"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "Please login with your EMSD Domain Account",
            USERNAME: "Please enter your Staff ID"
          },
          FORBIDDEN_SELF_REGISTRATION: "Please access the Messenger in E&M Connect to complete the account activation process. If you fail to complete this operation, your account will not be able to be activated.",
        },
        PASSWORD: {
          LOGIN_FAIL: "Login failed. Please log in again. If you do not have an Internet Email or LN Mail account, please apply one of them through EMSD Workflow Portal to get access right of instant messaging application.",
          ACCOUNT_SUSPENDED: "Your instant messaging application account is locked and you cannot log in. If you forget your password, please log in to E&M Connect through iAM Smart to reset your EMSD Domain Account. After resetting your account, please use E&M Connect to log in to the instant messaging application to unlock your account.",
          PASSWORD: "Domain Account Password",
          SECURE_PASSWORD: "Enter EMSD Domain Account Password",
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      GENERAL: {
        TEAMNOTE: "EMSD Messenger",
        TERMS_AND_CONDITIONS: "有關條款及細則",

        FILE_TIP: "大小限制: {{SIZE}}MB",
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "檔案"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "請使用機電署電腦開機帳戶(EMSD Domain Account) 登入",
            USERNAME: "請輸入員工編號"
          },
          FORBIDDEN_SELF_REGISTRATION: "請在 E&M Connect 中啟用 Messenger 功能,以完成帳號激活程序。未能完成此項操作,您的帳號將無法啟用。",
        },
        PASSWORD: {
          LOGIN_FAIL: "登入失敗，請重新登入。如閣下沒有Internet Email或LN Mail帳戶，請於EMSD Workflow Portal申請其中一個帳號，以取得即時通訊應用程式使用權限。",
          ACCOUNT_SUSPENDED: "你的即時通訊應用程式帳戶已被鎖定，無法登入。如閣下忘記密碼，請透過iAM Smart登入E&M Connect重設機電署電腦開機帳戶。重設帳戶後，請使用E&M Connect登入即時通訊應用程式，即可解除帳戶鎖定。",
          PASSWORD: "機電署電腦開機帳戶密碼",
          SECURE_PASSWORD: "輸入機電署電腦開機帳戶密碼",
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      GENERAL: {
        TEAMNOTE: "EMSD Messenger",
        TERMS_AND_CONDITIONS: "有关条款及细则",

        FILE_TIP: "大小限制: {{SIZE}}MB",
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "档案"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "请使用机电署电脑开机帐户(EMSD Domain Account) 登入",
            USERNAME: "请输入员工编号"
          },
          FORBIDDEN_SELF_REGISTRATION: "请在 E&M Connect 中启用 Messenger 功能,以完成帐号激活程序。未能完成此项操作,您的帐号将无法启用。",
        },
        PASSWORD: {
          LOGIN_FAIL: "登入失败，请重新登入。如阁下没有Internet Email或LN Mail帐户，请于EMSD Workflow Portal申请其中一个帐号，以取得即时通讯应用程式使用权限。",
          ACCOUNT_SUSPENDED: "你的即时通讯应用程式帐户已被锁定，无法登入。如阁下忘记密码，请透过iAM Smart登入E&M Connect重设机电署电脑开机帐户。重设帐户后，请使用E&M Connect登入即时通讯应用程式，即可解除帐户锁定。",
          PASSWORD: "机电署电脑开机帐户密码",
          SECURE_PASSWORD: "输入机电署电脑开机帐户密码"
        }
      }
    }
  }
];