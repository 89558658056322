import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Attachment } from '../../../models/attachment';

import * as _ from 'lodash';
import { AttachmentService, TimelinedAttachment } from '../attachment.service';
import { FileManagerService } from '../../file-manager/file-manager.service';

@Component({
  selector: 'tn-attachment-image-grid',
  templateUrl: './attachment-image-grid.component.html',
  styleUrls: ['./attachment-image-grid.component.scss']
})
export class AttachmentImageGridComponent implements OnInit {
  attachments: Attachment[] = [];
  attachmentIds: string[] = [];

  timelinedAttachments: TimelinedAttachment[] = [];

  constructor(
    public dialogRef: MatDialogRef<AttachmentImageGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileManagerService: FileManagerService,
    private _attachmentService: AttachmentService
  ) { }

  ngOnInit() {
    this.attachments = this.data.attachments;
    this.parseImages();
  }

  parseImages(): void {
    _.each(this.attachments, (a) => {
      if (this._fileManagerService.checkIfAttachmentIsGif(a.filename)) {
        // a.imgSrc = this._fileManagerService.getBackgroundImgSrcByAttachmentId(a.attachment_id);
        // return; // continue
        a.isGif = true;
      }

      a.imgSrc = this._fileManagerService.getBackgroundImgSrcByBase64(a.preview_image, a.content_type);
    });

    this.attachmentIds = _.map(this.attachments, 'attachment_id');

    this.attachments = _.orderBy(this.attachments, 'timestamp', 'desc');

    this.timelinedAttachments =  this._attachmentService.buildTimelinedAttachments(this.attachments);
  }

  onClick(image: Attachment): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(this.attachmentIds, image.attachment_id, this.data.editImageCallbackWithImageUrlAndCaption);
  }

}
