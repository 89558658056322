import { TeamNoteConfig } from "../../configs/models/config";

export const ArchsdConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "archsd"
  },
  MANIFEST: {
    PATH: "manifest/archsd.json"
  },
  GENERAL: {
    PAGE_TITLE: "ArchSD Chat",
    ELECTRON_HIDE_LOGOUT_BTN: true,
  },
  LOGIN: {
    PWA_LOGIN_REDIRECT_URL: 'https://im.archsd.gov.hk/sso/saml/web-client/archsd',
    PWA_LOGIN_REDIRECT_IPS: ['10.2.'],
    SSO_BUTTON_EXTRA_WORDINGS: 'For staff only'
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/archsd/notification_icon.png",
    FAVICON: "assets/images/clients/archsd/favicon.png",
    FAVICON_ALERT: "assets/images/clients/archsd/favicon_alert.png"
  },
  LOGGER: {
    ENABLE_LOGGER: false
  },
  WEBCLIENT: {
    GENERAL: {
      IS_ENABLE_CHAT_LIST_MESSAGE_SEARCH: true,
      IS_ENABLE_CHAT_ARCHIVE: true,
    },
    CONTACT: {
      CARD_DESC_FIELDS: [
        'title',
        'department',
        'email',
        'mobile',
        'public_message',
        'e_profile'
      ]
    },
    CHATROOM: {
      ENABLE_WHISPER: true
    },
    WORK_FLOW: {
      HIDE_FORM_LIST_MENU: true,
      HIDE_FORM_DETAIL_MENU: true,
      APPEND_FORM_SUBMIT_BTN: true,
    },
  },
  MAP: {
    API_KEY: 'AIzaSyDvi1qyMJetX6sHaDftjRcaPYVwr1tKzxw'
  }
};
